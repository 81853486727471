import React, { useEffect, useState } from "react";
import NewButton from "../../NewButton";
import {
  deleteDiscoveryInvitation,
  getDiscoveryInvitations
} from "../../../store/actions/discoveries";
import Icon from "../../Icon";
import { useString as s } from "../../StringProvider";
import styled from "styled-components";
import { bindActionCreators, compose } from "redux";
import {
  selectDiscoveryEditors,
  selectDiscoveryId,
  selectHasManageDiscoveryAccessPermission,
  selectRequestData,
  selectRequestParameters,
  selectRequestState
} from "../../../store/reducers";
import { connect } from "react-redux";
import useLoadingState from "../../../utils/use-loading-state";
import actionTypes from "../../../store/actionTypes";
import { formatDate } from "../../../utils/formatting";
import { useSetting } from "../../SettingsProvider";
import { themeProp } from "../../../theme";
import { DesktopOrTablet, Mobile, useMobileMediaQuery } from "../../Responsive";
import ButtonGroup from "../../ButtonGroup";
import Table from "../../Table";
import Text from "../../Text";
import Notification from "../../Notification";

const ManageDiscoveryAccess = ({
  discoveryId,
  discoveryInvitations,
  getDiscoveryInvitations,
  discoveryInvitationsParameters,
  deleteDiscoveryInvitation,
  deleteDiscoveryInvitationLoadingState,
  height,
  onPrevious,
  onClose,
  fixActionButtons = true,
  width,
  hasManageAccessPermission
}) => {
  const locale = useSetting("locale", "en-GB");
  const isMobile = useMobileMediaQuery();
  const [emailRemoved, setEmailRemoved] = useState("");
  const removeAccessText = s(
    "share.popup.button.removeAccess",
    "Remove access"
  );
  const removeAccessSuccess = s(
    "share.popup.messages.removeSuccess",
    "{email} removed",
    { email: emailRemoved }
  );
  const removeAccessFailure = s(
    "share.popup.messages.removeFailure",
    "Remove access failed"
  );
  const emailRowTitle = s("share.popup.row.email.text", "Email");
  const lastLoggedOnRowTitle = s(
    "share.popup.row.lastLoggedOn.text",
    "Last logged on"
  );
  const actionRowTitle = s("share.popup.row.action.text", "Action");
  const lastLoginEmptyText = s("share.popup.text.lastLoginEmpty", "Never");
  const subtitleText = s(
    "share.popup.text.subtitle",
    "The users below have edit access to the discovery:"
  );
  const noUsersText = s(
    "share.popup.text.noUsers",
    "There are no users with edit access to this discovery."
  );
  const backButtonText = s("share.popup.button.back", "Back to share");
  const closeButtonText = s("share.popup.button.close", "Close");

  const isCurrentDiscovery =
    discoveryInvitationsParameters?.discoveryId === discoveryId;

  useEffect(() => {
    getDiscoveryInvitations({ discoveryId });
  }, []);

  useLoadingState(
    deleteDiscoveryInvitationLoadingState,
    () => {
      getDiscoveryInvitations({ discoveryId });
      Notification.info(removeAccessSuccess);
    },
    () => {
      Notification.error(removeAccessFailure);
    }
  );

  const backButton = (
    <NewButton
      type={"primary"}
      onClick={onPrevious}
      data-cy={"manage-access-back-to-share-button"}
    >
      {backButtonText}
    </NewButton>
  );
  const closeButton = (
    <NewButton
      type={"secondary"}
      onClick={onClose}
      data-cy={"manage-access-close-button"}
    >
      {closeButtonText}
    </NewButton>
  );

  const actionButtons = (
    <>
      <Mobile>
        <ButtonGroup>
          {backButton}
          {onClose && closeButton}
        </ButtonGroup>
      </Mobile>
      <DesktopOrTablet>
        <ActionButtons>
          {backButton}
          {onClose && closeButton}
        </ActionButtons>
      </DesktopOrTablet>
    </>
  );

  const columns = [
    {
      title: emailRowTitle,
      dataIndex: "email",
      key: "email",
      ellipsis: true,
      width: "250px"
    },
    {
      title: lastLoggedOnRowTitle,
      dataIndex: "lastLoginAt",
      key: "lastLoginAt",
      width: "120px",
      render: (lastLoginAt) => (
        <Cell>
          {lastLoginAt
            ? formatDate({
                time: lastLoginAt,
                locale,
                separator: "."
              })
            : lastLoginEmptyText}
        </Cell>
      )
    },
    {
      title: actionRowTitle,
      dataIndex: "action",
      key: "action",
      width: "150px",
      render: (text, record) => {
        if (!hasManageAccessPermission) {
          return null;
        }
        return (
          <NewButton
            type={"text"}
            onClick={() => {
              setEmailRemoved(record.email);
              deleteDiscoveryInvitation({
                discoveryId,
                invitationId: record._id
              });
            }}
            data-cy={`manage-access-remove-access-button-${record.userId}`}
          >
            <Icon name={"newDelete"} size={"xlarge"} /> {removeAccessText}
          </NewButton>
        );
      }
    }
  ];

  return (
    <Container height={height} width={width}>
      {isCurrentDiscovery && (
        <TitleContainer>
          <Text>
            {discoveryInvitations?.length === 0 ? noUsersText : subtitleText}
          </Text>
        </TitleContainer>
      )}

      <div style={{ height: fixActionButtons ? undefined : "405px" }}>
        {isCurrentDiscovery && discoveryInvitations?.length > 0 && (
          <StyledTable
            removeHeaderStyle={true}
            columns={columns}
            dataSource={discoveryInvitations.map((item, index) => ({
              ...item,
              key: index
            }))}
            pagination={
              discoveryInvitations?.length > (isMobile ? 3 : 5)
                ? {
                    total: discoveryInvitations?.length,
                    pageSize: isMobile ? 3 : 5,
                    position: "bottomRight"
                  }
                : false
            }
          />
        )}
      </div>

      <Actions fixActionButtons={fixActionButtons}>{actionButtons}</Actions>
    </Container>
  );
};

const StyledTable = styled(Table)`
  .ant-table {
    background-color: inherit;
  }

  & thead.ant-table-thead > tr > th.ant-table-cell::before {
    content: none !important;
  }

  & .ant-table-thead .ant-table-cell {
    font-family: ${themeProp("typography.small.fontFamily")};
    font-size: ${themeProp("typography.small.fontSize")} !important;
    font-weight: ${themeProp("typography.bMedium.fontWeight")} !important;
    line-height: ${themeProp("typography.small.lineHeight")} !important;
    color: ${themeProp("palette.gray4")} !important;
    padding: 0 16px 4px 0;
    background: none !important;
    border: none;
  }

  & .ant-table-tbody .ant-table-cell {
    font-family: ${themeProp("typography.body.fontFamily")};
    font-size: ${themeProp("typography.body.fontSize")};
    font-weight: ${themeProp("typography.body.fontWeight")};
    line-height: ${themeProp("typography.body.lineHeight")};
    padding: 0;
    background: none;
    border: none;
    vertical-align: baseline;
  }

  & .ant-table-tbody .ant-table-cell.name {
    font-family: ${themeProp("typography.body.fontFamily")};
    font-size: ${themeProp("typography.body.fontSize")};
    font-weight: ${themeProp("typography.body.fontWeight")};
    line-height: ${themeProp("typography.body.lineHeight")};
  }
`;

const Container = styled.div`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
`;

const Cell = styled.div`
  height: 45px;
  padding: 10px;
`;

const TitleContainer = styled.div`
  margin-bottom: 24px;
`;

const Actions = styled.div`
  ${(props) => (props.fixActionButtons ? `bottom: 32px;` : null)};
  ${(props) => (props.fixActionButtons ? `position: absolute;` : null)};
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 16px;
`;

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getDiscoveryInvitations,
      deleteDiscoveryInvitation
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  discoveryId: selectDiscoveryId(state),
  discoveryEditors: selectDiscoveryEditors(state),
  discoveryInvitations: selectRequestData(
    state,
    actionTypes.GET_DISCOVERY_INVITATIONS_REQUEST
  ),
  discoveryInvitationsParameters: selectRequestParameters(
    state,
    actionTypes.GET_DISCOVERY_INVITATIONS_REQUEST
  ),
  deleteDiscoveryInvitationLoadingState: selectRequestState(
    state,
    actionTypes.DELETE_DISCOVERY_INVITATION_REQUEST
  ),
  hasManageAccessPermission: selectHasManageDiscoveryAccessPermission(state)
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ManageDiscoveryAccess
);
