import PropTypes from "prop-types";
import styled from "styled-components";
import { NewButton, Icon } from "../../../components";
import { useMobileOrTabletMediaQuery } from "../../../components/Responsive";
import AddOutcomeOverrideButton from "./AddOutcomeOverrideButton";
import {
  hasPermission,
  selectDiscoveryId,
  selectDiscoveryKPIs,
  selectDiscoveryRoiOverrides,
  selectRequestParameters,
  selectRequestState
} from "../../../store/reducers";
import Permissions from "../../../utils/permissions";
import { bindActionCreators, compose } from "redux";
import { deleteDiscoveryROIOverride } from "../../../store/actions/discoveries";
import { connect } from "react-redux";
import {
  startROIOverrideOperation,
  stopROIOverrideOperation
} from "../../../store/actions/roi-overrides-operations";
import useLoadingState from "../../../utils/use-loading-state";
import { useString as s } from "../../../components/StringProvider";
import actionTypes from "../../../store/actionTypes";
import Notification from "../../../components/Notification";

const ROIOverrides = ({
  discoveryId,
  discoveryKPIs,
  annualAdjustmentMode,
  outcomeOverrideType,
  roiOverrides,
  startROIOverrideOperation,
  stopROIOverrideOperation,
  deleteDiscoveryROIOverride,
  dataCy,
  deleteDiscoveryROIOverrideLoadingState,
  deleteDiscoveryROIOverrideRequestParameters,
  canUpdateROIValues
}) => {
  const mobileOrTablet = useMobileOrTabletMediaQuery();
  const rioOverrideList = [];

  const deleteSuccessMessage = s(
    "discovery.roi.overrideModal.delete.success",
    "Outcome override was removed"
  );

  const deleteFailureMessage = s(
    "discovery.roi.overrideModal.delete.failure",
    "Outcome override failed to delete"
  );

  useLoadingState(
    deleteDiscoveryROIOverrideLoadingState,
    () => {
      if (
        deleteDiscoveryROIOverrideRequestParameters.outcomeOverrideType ===
        outcomeOverrideType
      ) {
        stopROIOverrideOperation();
        Notification.info(deleteSuccessMessage);
      }
    },
    () => {
      if (
        deleteDiscoveryROIOverrideRequestParameters.outcomeOverrideType ===
        outcomeOverrideType
      ) {
        stopROIOverrideOperation();
        Notification.error(deleteFailureMessage);
      }
    }
  );

  const overrideKpiCodes =
    roiOverrides && roiOverrides[outcomeOverrideType]
      ? Object.keys(roiOverrides[outcomeOverrideType])
      : [];

  overrideKpiCodes.forEach((kpiCode) => {
    const name = discoveryKPIs.find(
      (discoveryKPI) => discoveryKPI.kpiCode === kpiCode
    )?.definition?.name;
    rioOverrideList.push({ kpiCode, name });
  });

  return annualAdjustmentMode ? (
    <Container data-cy={dataCy}>
      <AddOutcomeOverrideButton outcomeOverrideType={outcomeOverrideType} />
      {rioOverrideList.map((item, index) => (
        <ButtonWrapper key={index}>
          <OverrideTagButton
            data-cy={`override-tag`}
            disabled={!canUpdateROIValues}
            type={"iconTextTertiary"}
            onClick={() => {
              startROIOverrideOperation({
                kpiCode: item.kpiCode,
                outcomeOverrideType,
                isEdit: true
              });
            }}
          >
            <StyledIcon name={"pencilLight"} size={"mediumLarge"} />
            {item.name}
          </OverrideTagButton>
          <DeleteButton
            data-cy={`delete-override-button`}
            disabled={!canUpdateROIValues}
            type={"closeIcon"}
            onClick={() => {
              deleteDiscoveryROIOverride({
                discoveryId,
                kpiCode: item.kpiCode,
                outcomeOverrideType
              });
            }}
            mobileOrTablet={mobileOrTablet}
          >
            <Icon name={"deleteLight"} size={"mediumLarge"} colour={"gray3"} />
          </DeleteButton>
        </ButtonWrapper>
      ))}
    </Container>
  ) : null;
};

const StyledIcon = styled(Icon)`
  margin-top: -4px;
  margin-right: 6px;
`;

const Container = styled.div`
  margin-top: 20px;
  margin-right: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
`;

const ButtonWrapper = styled.div`
  position: relative;
  display: inline-block;
  max-width: fit-content;

  &:hover button {
    opacity: 1;
  }
`;

const OverrideTagButton = styled(NewButton)`
  max-width: 800px;
`;

const DeleteButton = styled(NewButton)`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(30px, -50%);
  opacity: ${({ $mobileOrTablet }) => ($mobileOrTablet ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  background-color: transparent;
  width: 30px;

  &:hover {
    cursor: pointer;
  }
`;

ROIOverrides.propTypes = {
  annualAdjustmentMode: PropTypes.bool.isRequired,
  outcomeOverrideType: PropTypes.string.isRequired,
  dataCy: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
  discoveryId: selectDiscoveryId(state),
  roiOverrides: selectDiscoveryRoiOverrides(state),
  discoveryKPIs: selectDiscoveryKPIs(state),
  canUpdateROIValues: hasPermission(state, Permissions.UPDATE_ROI_VALUES),
  deleteDiscoveryROIOverrideLoadingState: selectRequestState(
    state,
    actionTypes.DISCOVERY_DELETE_ROI_OVERRIDE_REQUEST
  ),
  deleteDiscoveryROIOverrideRequestParameters: selectRequestParameters(
    state,
    actionTypes.DISCOVERY_DELETE_ROI_OVERRIDE_REQUEST
  )
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteDiscoveryROIOverride,
      startROIOverrideOperation,
      stopROIOverrideOperation
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ROIOverrides
);
