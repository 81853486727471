import {
  selectRequestData,
  selectRequestState
} from "../../../../store/reducers";
import actionTypes from "../../../../store/actionTypes";
import { bindActionCreators, compose } from "redux";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styled, { css } from "styled-components";
import { useString as s } from "../../../../components/StringProvider";
import { getConversation } from "../../../../store/actions/ai";
import useLoadingState from "../../../../utils/use-loading-state";
import ButtonBar from "../ButtonBar";
import ChatMessage from "../../../../components/DiscoveryOperations/AIDiscoveryModal/ChatMessage";
import { ChatProvider } from "../../../../components/DiscoveryOperations/AIDiscoveryModal/ChatProvider";
import DescriptionList from "../../../../components/DescriptionList";
import AiChatRoleNames from "../../../../utils/constants/ai-chat-role-names";
import { themeProp } from "../../../../theme";
import formatMarkdownText from "../../../../utils/format-markdown-text";
import Markdown from "../../../../components/Discovery/Markdown";
import Toggle from "../../../../components/Toggle";
import MessageStats from "./MessageStats";
import Notification from "../../../../components/Notification";

const AIConversationDetails = ({
  loadingState,
  conversation,
  getConversation,
  match
}) => {
  const errorText = s(
    "aiConversationDetails.page.messages.getConversationFailed",
    "Failed to load conversation"
  );
  const model = s("aiConversationDetails.page.descriptionList.model", "Model");
  const temperature = s(
    "aiConversationDetails.page.descriptionList.temperature",
    "Temperature"
  );
  const discoveryId = s(
    "aiConversationDetails.page.descriptionList.discoveryId",
    "Discovery ID"
  );
  const options = s(
    "aiConversationDetails.page.descriptionList.options",
    "Options"
  );
  const emails = s(
    "aiConversationDetails.page.descriptionList.emails",
    "Emails"
  );
  const accessType = s(
    "aiConversationDetails.page.descriptionList.accessType",
    "Access Type"
  );
  const showHidden = s(
    "aiConversationDetails.page.showHidden",
    "Show Hidden Messages"
  );
  const conversationId = match.params.conversationId;
  const [showHiddenMessages, setShowHiddenMessages] = useState(true);

  useEffect(() => {
    if (conversationId) {
      getConversation({ conversationId });
    }
  }, [conversationId]);

  useLoadingState(
    loadingState,
    () => {},
    () => {
      Notification.error(errorText);
    }
  );

  const onChangeToggle = () => {
    setShowHiddenMessages((prev) => !prev);
  };

  return conversation ? (
    <Container>
      <ButtonBar>
        <ToggleContainer>
          <Toggle checked={showHiddenMessages} onChange={onChangeToggle} />{" "}
          {showHidden}
        </ToggleContainer>
      </ButtonBar>
      <Row>
        <div>
          <DescriptionList>
            <dt>{model}</dt>
            <dd>{conversation.model}</dd>
            <dt>{temperature}</dt>
            <dd>{conversation.temperature}</dd>
            <dt>{discoveryId}</dt>
            <dd>{conversation.discoveryId}</dd>
            <dt>{options}</dt>
            <dd>{conversation.options?.join(",")}</dd>
            <dt>{accessType}</dt>
            <dd>{conversation.accessType}</dd>
            <dt>{emails}</dt>
            <dd>{conversation.emails?.join(",")}</dd>
          </DescriptionList>
        </div>
        <Messages>
          {conversation.messages.map((item) =>
            !item?.hidden || showHiddenMessages ? (
              <Message
                key={item._id}
                data-message-id={item._id}
                data-message-author-role={item.role}
                role={item.role}
              >
                <MessageInner role={item.role}>
                  <StyledMarkdown paragraphMargin={true}>
                    {formatMarkdownText(item.content)}
                  </StyledMarkdown>
                  {!item?.hidden && item.role === AiChatRoleNames.ASSISTANT ? (
                    <MessageStats message={item} />
                  ) : null}
                </MessageInner>
              </Message>
            ) : null
          )}
        </Messages>
      </Row>
      <ChatProvider>
        <ChatMessage />
      </ChatProvider>
    </Container>
  ) : null;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  gap: 24px;
`;

const ToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-self: flex-start;
  gap: 16px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

const Messages = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Message = styled.div`
  width: 100%;
  display: flex;

  ${({ role }) =>
    role === AiChatRoleNames.USER &&
    css`
      justify-content: flex-end;
    `}
`;

const MessageInner = styled.article`
  padding: 16px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${({ role }) =>
    role === AiChatRoleNames.SYSTEM &&
    css`
      background-color: ${themeProp("palette.gray1")};
      border: 1px solid ${themeProp("palette.gray2")};
    `}

  ${({ role }) =>
    role === AiChatRoleNames.USER &&
    css`
      background-color: ${themeProp("palette.accent3")};
      max-width: 80%;
    `}

  ${({ role }) =>
    role === AiChatRoleNames.ASSISTANT &&
    css`
      background-color: ${themeProp("palette.surface")};
      border: 1px solid ${themeProp("palette.gray2")};
      max-width: 80%;
    `}
`;

const StyledMarkdown = styled(Markdown)`
  * {
    padding: 0;
    margin: 0;
  }

  h1 {
    font-size: ${themeProp(`typography.bLarge.fontSize`)};
    line-height: ${themeProp(`typography.bLarge.lineHeight`)};
    font-weight: ${themeProp(`typography.bLarge.fontWeight`)};
    font-family: ${themeProp(`typography.bLarge.fontFamily`)};
  }

  h2 {
    font-size: ${themeProp(`typography.bLargeMobile.fontSize`)};
    line-height: ${themeProp(`typography.bLargeMobile.lineHeight`)};
    font-weight: ${themeProp(`typography.bLargeMobile.fontWeight`)};
    font-family: ${themeProp(`typography.bLargeMobile.fontFamily`)};
  }

  h3,
  h4,
  h5,
  h6 {
    font-size: ${themeProp(`typography.bMedium.fontSize`)};
    line-height: ${themeProp(`typography.bMedium.lineHeight`)};
    font-weight: ${themeProp(`typography.bMedium.fontWeight`)};
    font-family: ${themeProp(`typography.bMedium.fontFamily`)};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding: 12px 0;
  }

  p {
    font-size: ${themeProp(`typography.body.fontSize`)};
    line-height: ${themeProp(`typography.body.lineHeight`)};
    font-weight: ${themeProp(`typography.body.fontWeight`)};
    font-family: ${themeProp(`typography.body.fontFamily`)};
  }

  hr {
    margin: 12px 0;
    border: 0;
    border-top: 1px solid ${themeProp("palette.gray2")};
  }

  ul,
  ol,
  li {
    padding-bottom: 8px;
    list-style-position: outside;
    padding-left: 5px;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  li {
    margin-left: 10px;
  }

  table {
    display: block;
    border-collapse: collapse;
    margin: 12px 0;
    max-width: 604px;
    overflow-x: scroll;
  }

  th,
  td {
    border-bottom: 1px solid ${themeProp("palette.gray2")};
    padding: 16px;
    text-align: left;
    font-size: ${themeProp(`typography.bodyMobile.fontSize`)};
    line-height: ${themeProp(`typography.bodyMobile.lineHeight`)};
    font-weight: ${themeProp(`typography.bodyMobile.fontWeight`)};
    font-family: ${themeProp(`typography.bodyMobile.fontFamily`)};
  }

  th {
    font-family: ${themeProp(`typography.bMedium.fontFamily`)};
    color: ${themeProp("palette.gray4")};
    border: none;

    &:first-child {
      border-top-left-radius: 8px;
    }

    &:last-child {
      border-top-right-radius: 8px;
    }
  }

  thead {
    background-color: ${themeProp("palette.gray1")};
    border: none;
  }

  code {
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  pre {
    background-color: ${themeProp("palette.gray5")};
    padding: 8px;
    border-radius: 6px;
    margin-bottom: 16px;
  }
`;

const mapStateToProps = (state) => ({
  loadingState: selectRequestState(state, actionTypes.GET_CONVERSATION_REQUEST),
  conversation: selectRequestData(state, actionTypes.GET_CONVERSATION_REQUEST)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getConversation
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AIConversationDetails
);
