import { useString as s } from "../../StringProvider";
import PropTypes from "prop-types";
import Modal from "../../Modal";
import React, { useEffect, useState } from "react";
import useLoadingState from "../../../utils/use-loading-state";
import { selectRequestState } from "../../../store/reducers";
import actionTypes from "../../../store/actionTypes";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getDiscoveryNotifications } from "../../../store/actions/discoveries";
import EngagementNotificationsTable from "../../Notifications/EngagementNotificationsTable";
import styled from "styled-components";
import Icon from "../../Icon";
import TooltipIcon from "../../TooltipIcon";
import Text from "../../Text";
import Notification from "../../Notification";

const DiscoveryEngagementModal = ({
  discoveryId,
  discoveryName,
  externalEngagementCount,
  externalReportViewCount,
  loadingState,
  getDiscoveryNotifications,
  onClose
}) => {
  const [ready, setReady] = useState(false);
  const engagementTitle = s(
    "engagement.popup.engagement.title",
    "Customer Engagement"
  );
  const engagementTooltip = s(
    "engagement.popup.engagement.tooltip",
    "Count of all actions by customers (people outside your organization), including Report Views."
  );

  const reportViewsTitle = s(
    "engagement.popup.reportViews.title",
    "Customer Report Views"
  );
  const reportViewsTooltip = s(
    "engagement.popup.reportViews.tooltip",
    "Count of Report Views by customers."
  );
  const errorText = s(
    "engagement.popup.errorText",
    "Error loading the engagement report"
  );

  useEffect(() => {
    getDiscoveryNotifications({
      discoveryId,
      external: true,
      start: 0,
      count: 6
    });
  }, [discoveryId]);

  useLoadingState(
    loadingState,
    () => {
      setReady(true);
    },
    () => {
      Notification.error(errorText);
    }
  );

  const EngagementCount = ({
    title,
    iconName,
    count,
    tooltip,
    tooltipButtonDataCy,
    tooltipDataCy
  }) => (
    <EngagementCountContainer>
      <Row>
        <IconContainer>
          <Icon name={iconName} size={"larger"} />
        </IconContainer>
        <Text variant={"h3"} color={"black"}>
          {count}
        </Text>
      </Row>
      <Row>
        <Text variant={"body"} color={"black"}>
          {title}
        </Text>
        <TooltipIcon
          dataCy={tooltipButtonDataCy}
          dataCyTooltip={tooltipDataCy}
          title={tooltip}
          iconName={"helpGrey"}
        />
      </Row>
    </EngagementCountContainer>
  );

  const Header = () => (
    <EngagementCountsContainer>
      <EngagementCount
        title={engagementTitle}
        iconName={"trending"}
        count={externalEngagementCount}
        tooltip={engagementTooltip}
        tooltipButtonDataCy={"engagementTooltipButton"}
        tooltipDataCy={"engagementTooltip"}
      />
      <EngagementCount
        title={reportViewsTitle}
        iconName={"laptopWithTick"}
        count={externalReportViewCount}
        tooltip={reportViewsTooltip}
        tooltipButtonDataCy={"reportViewsTooltipButton"}
        tooltipDataCy={"reportViewsTooltip"}
      />
    </EngagementCountsContainer>
  );

  return (
    ready && (
      <Modal
        title={s(
          "engagement.popup.header",
          "Engagement Report for {discoveryName}",
          { discoveryName }
        )}
        width="1100px"
        open={true}
        onCancel={onClose}
        data-cy={"discovery-engagement-modal"}
      >
        <ReportContainer>
          <Header />
          <EngagementNotificationsTable discoveryId={discoveryId} />
        </ReportContainer>
      </Modal>
    )
  );
};

const IconContainer = styled.div`
  margin-top: 3px;
`;
const ReportContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
  padding-top: 16px; //the modal header has a margin-bottom of 8px
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const EngagementCountsContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 577px) {
    flex-direction: column;
  }
  gap: 24px;
  justify-content: space-between;
`;

const EngagementCountContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px;
  border: 1px solid ${(props) => props.theme.palette.gray2};
  border-radius: 6px;
  flex-grow: 1;
`;

DiscoveryEngagementModal.propTypes = {
  discoveryId: PropTypes.string.isRequired,
  discoveryName: PropTypes.string.isRequired,
  externalReportViewCount: PropTypes.number.isRequired,
  externalEngagementCount: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  loadingState: selectRequestState(
    state,
    actionTypes.GET_DISCOVERY_NOTIFICATIONS_REQUEST
  )
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getDiscoveryNotifications
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(DiscoveryEngagementModal);
