import React, { useState } from "react";
import styled from "styled-components";
import { themeProp } from "../../../theme";
import EmailModalForm from "./EmailModalForm";
import EmailModalEditor from "./EmailModalEditor";
import {
  selectDiscoveryPhase,
  selectRequestData,
  selectRequestState
} from "../../../store/reducers";
import { compose } from "redux";
import { connect } from "react-redux";
import actionTypes from "../../../store/actionTypes";
import useLoadingState from "../../../utils/use-loading-state";
import JobMonitor from "../../JobMonitor";
import JobStatuses from "../../../utils/job-statuses";
import { useString as s } from "../../StringProvider";
import Notification from "../../Notification";

const EmailModalContainer = ({ phase, emailContentJob, loadingState }) => {
  const [formValues, setFormValues] = useState({
    template: phase,
    selectedLinks: [],
    shareAccess: "",
    editAccessEmails: []
  });
  const [isContentGenerated, setIsContentGenerated] = useState(false);
  const [isContentLoading, setIsContentLoading] = useState(false);
  const [jobId, setJobId] = useState("");
  const [emailContent, setEmailContent] = useState(null);
  const emailGenerationFailed = s(
    "email.popup.email.generation.failed",
    "Failed to generate email"
  );

  useLoadingState(loadingState, () => {
    setJobId(emailContentJob._id);
  });

  const onJobChange = (job) => {
    switch (job.status) {
      case JobStatuses.FAILED:
        setIsContentGenerated(false);
        setIsContentLoading(false);
        Notification.error(emailGenerationFailed);
        setJobId("");
        break;

      case JobStatuses.STARTED:
        setIsContentGenerated(false);
        setIsContentLoading(true);
        break;

      case JobStatuses.COMPLETED:
        setIsContentGenerated(true);
        setIsContentLoading(false);
        setEmailContent(job.results);
        break;
      default:
        return;
    }
  };

  return (
    <Container>
      {jobId ? <JobMonitor jobId={jobId} onChange={onJobChange} /> : null}
      <EmailModalForm
        isContentGenerated={isContentGenerated}
        isContentLoading={isContentLoading}
        setIsContentGenerated={setIsContentGenerated}
        setIsContentLoading={setIsContentLoading}
        formValues={formValues}
        setFormValues={setFormValues}
      />
      <EmailModalEditor
        isContentGenerated={isContentGenerated}
        isContentLoading={isContentLoading}
        emailContent={emailContent}
      />
    </Container>
  );
};

const Container = styled.section`
  width: 100%;
  display: flex;
  gap: 6px;

  & > * {
    min-height: 628px;
    border-radius: 4px;
    border: 1px solid ${themeProp("palette.gray2")};
  }

  @media screen and (max-width: 754px) {
    flex-wrap: wrap;
  }
`;

const mapStateToProps = (state) => ({
  phase: selectDiscoveryPhase(state),
  emailContentJob: selectRequestData(
    state,
    actionTypes.DISCOVERY_GENERATE_EMAIL_REQUEST
  ),
  loadingState: selectRequestState(
    state,
    actionTypes.DISCOVERY_GENERATE_EMAIL_REQUEST
  )
});

export default compose(connect(mapStateToProps))(EmailModalContainer);
