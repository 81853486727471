import {
  selectDiscoveryCurrency,
  selectDiscoveryId,
  selectDiscoveryKPI,
  selectDiscoveryNewKPIOrder,
  selectDiscoverySetting,
  selectRequestError,
  selectRequestState
} from "../../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { useEffect } from "react";
import { useString as s } from "../../StringProvider";
import {
  createDiscoveryKPI,
  stopCreatingKPI
} from "../../../store/actions/create-kpis";
import NewKPIForm from "./NewKPIForm";
import Form from "../../Form";
import PropType from "prop-types";
import ViewKPIsBy from "../../../utils/view-kpis-by";
import KPIModal from "./KPIModal";
import actionTypes from "../../../store/actionTypes";
import useLoadingState from "../../../utils/use-loading-state";
import Notification from "../../Notification";

const CreateKPIModal = ({
  discoveryId,
  kpiCode, //required by mapStateToProps
  loadingState,
  discoveryKPI,
  order,
  error,
  createDiscoveryKPI,
  stopCreatingKPI,
  currency
}) => {
  const errorText = s("createKPI.popup.error.text", "Failed to create outcome");
  const successText = s(
    "createKPI.popup.success.text",
    "Card added! Changes will be applied to the current discovery only."
  );
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, []);

  useLoadingState(
    loadingState,
    () => {
      Notification.success(successText);
      stopCreatingKPI();
    },
    () => {
      if (!error || error.status !== 409) {
        Notification.error(errorText);
      }
    }
  );

  const onFinish = ({
    name,
    benefitValueDriverDescription,
    lowImpactPercentage,
    highImpactPercentage,
    categoryCode,
    challengeMappings,
    detailText
  }) => {
    const data = {
      lowImpactPercentage,
      highImpactPercentage,
      definition: {
        name,
        benefitValueDriverDescription,
        categoryCode,
        groupCode: discoveryKPI?.definition?.groupCode,
        order,
        challengeMappings,
        detailText
      }
    };

    createDiscoveryKPI({
      discoveryId,
      data
    });
  };

  return (
    <KPIModal open={true} data-cy={"create-kpi-modal"}>
      <NewKPIForm
        currency={currency}
        onFinish={onFinish}
        onCancel={stopCreatingKPI}
        form={form}
        error={error}
      />
    </KPIModal>
  );
};

CreateKPIModal.propTypes = {
  kpiCode: PropType.string.isRequired
};

const mapStateToProps = (state, props) => {
  const viewBy = selectDiscoverySetting(state, "viewKPIsBy", ViewKPIsBy.GROUP);

  return {
    discoveryId: selectDiscoveryId(state),
    loadingState: selectRequestState(
      state,
      actionTypes.DISCOVERY_CREATE_KPI_REQUEST
    ),
    error: selectRequestError(state, actionTypes.DISCOVERY_CREATE_KPI_REQUEST),
    discoveryKPI: selectDiscoveryKPI(state, props.kpiCode),
    order: selectDiscoveryNewKPIOrder(state, props.kpiCode, viewBy),
    currency: selectDiscoveryCurrency(state)
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createDiscoveryKPI,
      stopCreatingKPI
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  CreateKPIModal
);
