import {
  selectDiscoveryCanvasLogoPath,
  selectDiscoveryId,
  selectRequestData,
  selectRequestParameters,
  selectRequestState
} from "../../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import NewButton from "../../../components/NewButton";
import { useString as s } from "../../../components/StringProvider";
import styled from "styled-components";
import {
  FileDragger,
  FileUpload
} from "../../Organization/ManageConfiguration/components";
import React, { useEffect, useState } from "react";
import { updateDiscoveryCanvas } from "../../../store/actions/discoveries";
import actionTypes from "../../../store/actionTypes";
import useLoadingState from "../../../utils/use-loading-state";
import { Modal } from "../../../components";
import ImagePreview from "./ImagePreview";
import Input from "../../../components/Input";
import Form, { FormItem } from "../../../components/Form";
import Notification from "../../../components/Notification";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const EditCanvasHeaderModal = ({
  discoveryId,
  onClose,
  canvasTitle,
  updateDiscoveryCanvas,
  uploadLoadingState,
  uploadParameters,
  createFileState,
  createFileData,
  canvasLogoPath
}) => {
  const [form] = Form.useForm();
  const canvasTitleLocal = Form.useWatch("canvasTitle", form);
  const [preview, setPreview] = useState(
    canvasLogoPath ? "/" + canvasLogoPath : ""
  );
  const [uploadComplete, setUploadComplete] = useState(!!canvasLogoPath);
  const [removeLogoClicked, setRemoveLogoClicked] = useState(false);
  const [fileId, setFileId] = useState();
  const [fileList, setFileList] = useState([]);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const maxFileSize = 200 * 1000;
  const acceptTypes = ".jpg,.jpeg,.png,.webp";
  const popupTitleText = s("canvas.popup.header", "Edit header");
  const saveButtonText = s("canvas.popup.save.text", "Save");
  const removeLogoButtonText = s("canvas.popup.remove.text", "Remove logo");
  const titleText = s("canvas.popup.title.text", "Title");
  const logoText = s("canvas.popup.logo.text", "Logo");
  const successMessage = s(
    "canvas.popup.messages.success",
    "The Canvas was updated"
  );
  const failureMessage = s(
    "canvas.popup.messages.failure",
    "Failed to update the Canvas"
  );
  const fileDraggerHeader = s(
    "canvas.popup.fileDragger.description",
    "Drop logo here or click this area to upload"
  );

  const checkDisabled = ({ canvasTitleLocal, fileId, removeLogoClicked }) => {
    const canvasTitleChanged = canvasTitleLocal !== canvasTitle;

    if (!canvasTitleLocal) {
      setSubmitDisabled(true);
    } else if (removeLogoClicked) {
      setSubmitDisabled(false);
    } else if (!canvasTitleChanged && !fileId) {
      setSubmitDisabled(true);
    } else {
      setSubmitDisabled(false);
    }
  };

  useEffect(() => {
    checkDisabled({
      canvasTitleLocal,
      fileId,
      removeLogoClicked
    });
  }, [fileId, removeLogoClicked, canvasTitleLocal]);

  const handleRemove = () => {
    if (preview.startsWith("data:")) {
      setPreview("");
      setFileList([]);
      setUploadComplete(false);
      setFileId(undefined);
    } else {
      setRemoveLogoClicked(true);
    }
  };

  useLoadingState(
    uploadLoadingState,
    () => {
      if (uploadParameters.canvasLogoFileId || uploadParameters.canvasTitle) {
        Notification.success(successMessage);

        setRemoveLogoClicked(false);
        onClose();
      } else {
        setPreview("");
        setUploadComplete(false);
        setFileId(undefined);
      }
    },
    () => {
      Notification.error(failureMessage);
    }
  );

  useLoadingState(createFileState, () => {
    setFileId(createFileData?.file?._id);
  });

  const onStartUpload = (file) => {
    setFileList((list) => [...list, file]);
    getBase64(file).then((data) => setPreview(data));
    return false;
  };

  const onDeleteFile = () => {
    setFileId(undefined);
    setPreview("");
    setFileList([]);
  };

  const saveCanvasProperties = ({ canvasTitle }) => {
    updateDiscoveryCanvas({
      discoveryId,
      canvasLogoFileId: removeLogoClicked && !fileId ? null : fileId,
      canvasTitle
    });
  };

  const onFileUploadComplete = () => {
    setUploadComplete(true);
  };

  const renderLogoForm = () =>
    fileList.length === 0 ? (
      <FileDragger
        acceptTypes={acceptTypes}
        maxFileSize={maxFileSize}
        onStartUpload={onStartUpload}
        multiple={false}
        header={fileDraggerHeader}
      />
    ) : (
      fileList.map((file, index) => (
        <FileUpload
          localTag={"canvas"}
          key={index}
          file={file}
          onDelete={onDeleteFile}
          onUploadComplete={onFileUploadComplete}
          showFileIcon={false}
          acceptTypes={acceptTypes}
          maxFileSize={maxFileSize}
        />
      ))
    );

  const renderPreview = () => (
    <>
      <ImagePreview imageUrl={preview} />
    </>
  );

  return (
    <Modal
      title={popupTitleText}
      visible={true}
      onCancel={onClose}
      style={{ minWidth: 540, maxWidth: 540, maxHeight: 300, minHeight: 300 }}
    >
      <Container direction={"vertical"} size={24}>
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            canvasTitle
          }}
          onFinish={saveCanvasProperties}
        >
          <FormItem label={titleText} name="canvasTitle">
            <Input />
          </FormItem>
          <FormItem label={logoText}>
            {!uploadComplete || !preview || removeLogoClicked
              ? renderLogoForm()
              : renderPreview()}
          </FormItem>
          <Buttons>
            <NewButton type={"submit"} disabled={submitDisabled}>
              {saveButtonText}
            </NewButton>
            {(fileId || canvasLogoPath) && (
              <NewButton type={"secondary"} onClick={handleRemove}>
                {removeLogoButtonText}
              </NewButton>
            )}
          </Buttons>
        </Form>
      </Container>
    </Modal>
  );
};

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  gap: 24px;
  min-height: 200px;
`;

const mapStateToProps = (state) => ({
  uploadLoadingState: selectRequestState(
    state,
    actionTypes.UPDATE_DISCOVERY_CANVAS_REQUEST
  ),
  uploadParameters: selectRequestParameters(
    state,
    actionTypes.UPDATE_DISCOVERY_CANVAS_REQUEST
  ),
  discoveryId: selectDiscoveryId(state),
  createFileState: selectRequestState(state, actionTypes.CREATE_FILE_REQUEST),
  createFileData: selectRequestData(state, actionTypes.CREATE_FILE_REQUEST),
  canvasLogoPath: selectDiscoveryCanvasLogoPath(state)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateDiscoveryCanvas
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  EditCanvasHeaderModal
);
