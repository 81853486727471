import {
  selectRequestData,
  selectRequestState
} from "../../../../store/reducers";
import actionTypes from "../../../../store/actionTypes";
import { bindActionCreators, compose } from "redux";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { useString as s } from "../../../../components/StringProvider";
import Table from "../../../../components/Table";
import { getConversationPrompts } from "../../../../store/actions/ai";
import NewButton from "../../../../components/NewButton";
import UploadAIPromptTemplatesModal from "./UploadAIPromptTemplatesModal";
import useLoadingState from "../../../../utils/use-loading-state";
import ButtonBar from "../ButtonBar";
import Notification from "../../../../components/Notification";

const AIPromptTemplates = ({
  loadingState,
  prompts,
  getConversationPrompts
}) => {
  const errorText = s(
    "aiPromptTemplates.page.messages.getPromptsFailed",
    "Failed to load prompt templates"
  );

  const button = s(
    "aiPromptTemplates.page.upload.button",
    "Upload AI Prompt Templates"
  );
  const [showModal, setShowModal] = useState(false);

  const data =
    (prompts &&
      prompts.map((prompt) => ({
        ...prompt,
        key: prompt.name
      }))) ||
    [];

  useEffect(() => {
    getConversationPrompts();
  }, []);

  useLoadingState(
    loadingState,
    () => {},
    () => {
      Notification.error(errorText);
    }
  );

  const onUpload = () => {
    setShowModal(true);
  };

  const onClose = () => {
    setShowModal(false);
  };

  const columns = [
    {
      title: s("aiPromptTemplates.page.table.name", "AI Prompt Template"),
      dataIndex: "name",
      key: "name",
      sorter: false
    }
  ];

  return (
    <Container>
      <ButtonBar>
        <NewButton
          type={"primary"}
          onClick={onUpload}
          data-cy={"upload-prompt-templates"}
        >
          {button}
        </NewButton>
      </ButtonBar>
      <Table
        columns={columns}
        dataSource={data}
        variant={"smallBody"}
        pagination={false}
      />
      {showModal ? <UploadAIPromptTemplatesModal onClose={onClose} /> : null}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  gap: 24px;
`;

const mapStateToProps = (state) => ({
  loadingState: selectRequestState(
    state,
    actionTypes.GET_CONVERSATION_PROMPTS_REQUEST
  ),
  prompts: selectRequestData(
    state,
    actionTypes.GET_CONVERSATION_PROMPTS_REQUEST
  )
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getConversationPrompts
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AIPromptTemplates
);
