import { useEffect, useState } from "react";
import { CenteredPageLayout } from "../../components/Layout";
import { Space } from "antd";
import Heading from "../../components/Heading";
import { Text } from "../../components";
import NewButton from "../../components/NewButton";
import Page from "../../components/Page";
import Header, { BrandBadge } from "../../components/Header";
import { useString as s } from "../../components/StringProvider";
import styled from "styled-components";
import Icon from "../../components/Icon";
import Form, { FormItem } from "../../components/Form";
import Input from "../../components/Input";
import {
  selectRequestError,
  selectRequestState,
  selectUser,
  hasPermission
} from "../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { requestAccess } from "../../store/actions/access";
import actionTypes from "../../store/actionTypes";
import useLoadingState from "../../utils/use-loading-state";
import { getReportLink } from "../../components/DiscoveryOperations/ShareDiscoveryModal/functions";
import openInNewTab from "../../utils/open-in-new-tab";
import Permissions from "../../utils/permissions";
import Notification from "../../components/Notification";

const DiscoveryErrorPageHeader = ({}) => {
  return (
    <Header>
      <BrandBadge linkTo={"/"} />
    </Header>
  );
};

const DiscoveryForbiddenPage = ({
  discoveryId,
  error,
  user,
  requestAccessLoadingState,
  requestAccess,
  requestAccessError,
  canSeeMyDiscoveries
}) => {
  const [username, setUsername] = useState("");
  const [discoveryName, setDiscoveryName] = useState("");
  const [myUsername, setMyUsername] = useState("");
  const [complete, setComplete] = useState(false);
  const header = s(
    "discovery_forbidden.page.header",
    "Discovery for {discoveryName}",
    { discoveryName }
  );
  const subheader = s(
    "discovery_forbidden.page.subheader",
    "Not your fault, but you don’t have access to edit this discovery. You can open the report or request access to edit the discovery."
  );
  const successHeader = s(
    "discovery_forbidden.page.success.header",
    "Request sent"
  );
  const successSubheader = s(
    "discovery_forbidden.page.success.subheader",
    "We’ll send you an email when the discovery is shared with you."
  );
  const discoveryOwner = s(
    "discovery_forbidden.page.discoveryOwner",
    "Discovery owner"
  );
  const youreSignedInAs = s(
    "discovery_forbidden.page.youreSignedInAs",
    "You’re signed in as"
  );
  const messagePlaceholder = s(
    "discovery_forbidden.page.message.placeholder",
    "Comment (optional)"
  );
  const errorMessage = s(
    "discovery_forbidden.page.messages.error",
    "Failed to request access"
  );
  const requestAccessButton = s(
    "discovery_forbidden.page.requestAccess",
    "Request access"
  );
  const openReportButton = s(
    "discovery_forbidden.page.openReport",
    "Open report"
  );

  useEffect(() => {
    const { owner, discoveryName } = error?.data?.data ? error.data.data : {};
    setUsername(owner);
    setDiscoveryName(discoveryName);
    setComplete(false);
  }, [error]);

  useEffect(() => {
    if (user) {
      setMyUsername(user.email);
    }
  }, [user]);

  useLoadingState(
    requestAccessLoadingState,
    () => {
      setComplete(true);
    },
    () => {
      if (requestAccessError && requestAccessError.status === 409) {
        setComplete(true);
      } else {
        Notification.error(errorMessage);
      }
    }
  );

  const onOpenReport = () => {
    const { reportId } = error?.data?.data ? error.data.data : {};

    const url = getReportLink({ reportId });
    openInNewTab(url);
  };

  const onFinish = ({ message }) => {
    requestAccess({ discoveryId, message });
  };

  return complete ? (
    <Page header={<DiscoveryErrorPageHeader />}>
      <CenteredPageLayout data-cy={"discovery-forbidden-page"}>
        <Container data-cy={"discovery-request-access-success"}>
          <Space direction={"vertical"} size={16}>
            <Icon colour={"success"} size={"xlarge"} name={"check"} />
            <Heading level={"h3"}>{successHeader}</Heading>
            <Text color={"gray4"}>{successSubheader}</Text>
          </Space>
        </Container>
      </CenteredPageLayout>
    </Page>
  ) : (
    <Page header={<DiscoveryErrorPageHeader />}>
      <CenteredPageLayout data-cy={"discovery-forbidden-page"}>
        <Container>
          <ContainerInner direction={"vertical"} size={0}>
            <Icon colour={"primary"} size={"xlarge"} name={"lock"} />
            <Heading level={"h3"} data-cy={"discovery-forbidden-page-header"}>
              {header}
            </Heading>
            <Space direction={"vertical"} size={8}>
              <Text color={"gray4"}>{subheader}</Text>
              {canSeeMyDiscoveries && (
                <DiscoveryOwner>
                  <Text variant={"small"} color={"gray4"}>
                    {discoveryOwner}
                  </Text>{" "}
                  <Text
                    variant={"small"}
                    data-cy={"discovery-forbidden-page-discovery-owner"}
                  >
                    {username}
                  </Text>
                </DiscoveryOwner>
              )}
            </Space>
            <FormContainer>
              <Form name="basic" onFinish={onFinish}>
                <FormItem name="message" rules={[{}]}>
                  <Input
                    placeholder={messagePlaceholder}
                    data-cy={"discovery-forbidden-page-request-access-form"}
                  />
                </FormItem>
                <ButtonContainer size={16}>
                  <NewButton
                    type={"submit"}
                    data-cy={"discovery-forbidden-page-request-access"}
                  >
                    {requestAccessButton}
                  </NewButton>
                  <NewButton type={"secondary"} onClick={onOpenReport}>
                    <Icon name={"open"} />
                    {openReportButton}
                  </NewButton>
                </ButtonContainer>
              </Form>
            </FormContainer>
            <div>
              <Text variant={"small"} color={"gray4"}>
                {youreSignedInAs}
              </Text>{" "}
              <Text
                variant={"small"}
                data-cy={"discovery-forbidden-page-signed-username"}
              >
                {myUsername}
              </Text>
            </div>
          </ContainerInner>
        </Container>
      </CenteredPageLayout>
    </Page>
  );
};

const FormContainer = styled.div`
  margin-top: 32px;
`;

const Container = styled.div`
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 640px;
  text-align: center;

  h3 {
    text-align: center;
    margin-bottom: 8px;
  }
`;

const ContainerInner = styled(Space)`
  & > * {
    margin-bottom: 32px;
  }

  .ant-space-item:nth-child(2) {
    margin-bottom: 8px;
  }

  .ant-space-item:nth-child(3) {
    margin-bottom: 0px;
  }
`;

const ButtonContainer = styled(Space)`
  margin-top: 32px;
`;

const DiscoveryOwner = styled.div`
  margin-top: 8px;
`;

const mapStateToProps = (state) => ({
  user: selectUser(state),
  requestAccessLoadingState: selectRequestState(
    state,
    actionTypes.REQUEST_ACCESS_REQUEST
  ),
  requestAccessError: selectRequestError(
    state,
    actionTypes.REQUEST_ACCESS_REQUEST
  ),
  canSeeMyDiscoveries: hasPermission(state, Permissions.SEE_MY_DISCOVERIES)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestAccess
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  DiscoveryForbiddenPage
);
