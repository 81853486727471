import React, { useContext, useState } from "react";
import { Text } from "../../../components";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import Form, { FormItem } from "../../../components/Form";
import NewButton from "../../../components/NewButton";
import { useString as s } from "../../../components/StringProvider";
import ButtonGroup from "../../../components/ButtonGroup";
import actionTypes from "../../../store/actionTypes";
import styled from "styled-components";
import SelectOutcomesOverride from "./SelectOutcomesOverride";
import ListOfPercentageValues from "./ListOfPercentageValues";
import { SliderInput } from "../../../components/Input";
import { OutcomeOverrideTypes } from "./OutcomeOverrideModal";
import { useMobileMediaQuery } from "../../../components/Responsive";
import { SettingsContext } from "../../../components/SettingsProvider";
import useLoadingState from "../../../utils/use-loading-state";
import _ from "lodash";
import {
  hasPermission,
  selectDiscovery,
  selectDiscoveryId,
  selectDiscoveryKPIs,
  selectDiscoveryRoiOverrides,
  selectRequestParameters,
  selectRequestState,
  selectROIOverrideIsEdit,
  selectROIOverrideKpiCode,
  selectROIOverrideType,
  selectROIFormValues
} from "../../../store/reducers";
import Permissions from "../../../utils/permissions";
import { saveDiscoveryROIOverride } from "../../../store/actions/discoveries";
import { stopROIOverrideOperation } from "../../../store/actions/roi-overrides-operations";
import Notification from "../../../components/Notification";

const OutcomeOverrideForm = ({
  discoveryId,
  discovery,
  roiOverrides,
  saveDiscoveryROIOverride,
  saveDiscoveryROIOverrideLoadingState,
  saveDiscoveryROIOverrideRequestParameters,
  stopROIOverrideOperation,
  outcomeOverrideType,
  canUpdateROIValues,
  overrideKpiCode,
  overrideIsEdit,
  overrideEvaluationPeriod,
  overrideBenefitAdjustmentPerYear,
  overrideAdoptionTime,
  overrideImplementationLength,
  form,
  inputLabel
}) => {
  const isMobile = useMobileMediaQuery();
  const { getSetting } = useContext(SettingsContext);
  const [saveDisabled, setSaveDisabled] = useState(true);

  const benefitAdjustmentValidation = s(
    "discovery.roi.form.benefitAdjustment.validation",
    "Benefit adjustment must be greater than zero"
  );

  const implementationLengthMin = getSetting(
    "discovery.roi.implementationLength.min",
    1
  );
  const implementationLengthMax = getSetting(
    "discovery.roi.implementationLength.max",
    12
  );

  const adoptionTimeMin = getSetting("discovery.roi.adoptionTime.min", 1);
  const adoptionTimeMax = getSetting("discovery.roi.adoptionTime.max", 12);

  const defaultValues = {
    implementationLength: overrideImplementationLength,
    adoptionTime: overrideAdoptionTime,
    benefitAdjustmentPerYear: overrideBenefitAdjustmentPerYear
  };

  const adoptionTimeMarks = Array.from(
    Array(adoptionTimeMax - adoptionTimeMin + 1).keys()
  )
    .map((i) => i + adoptionTimeMin)
    .reduce((map, n) => {
      map[n] = n;
      return map;
    }, {});

  const implementationLengthMarks = Array.from(
    Array(implementationLengthMax - implementationLengthMin + 1).keys()
  )
    .map((i) => i + implementationLengthMin)
    .reduce((map, n) => {
      map[n] = n;
      return map;
    }, {});

  const descriptionLabel = s(
    "discovery.roi.overrideModal.description.label",
    "Please choose an Outcome and override the project level assumption if required."
  );

  const disabledSaveButtonTooltip = s(
    "discovery.roi.overrideModal.saveButton..disabled.tooltip",
    "Please choose an Outcome and set a new value different from the default"
  );

  const outcomeLabel = s(
    "discovery.roi.overrideModal.outcome.label",
    "Outcome"
  );

  const saveLabel = s("discovery.roi.overrideModal.save.label", "Save");
  const cancelLabel = s("discovery.roi.overrideModal.cancel.label", "Cancel");

  const roiOverrideSaveFailure = s(
    "discovery.roi.overrideModal.create.failed",
    "The ROI Override could not be saved"
  );
  const roiOverrideSaveSuccess = s(
    "discovery.roi.overrideModal.create.success",
    "Outcome Override was successfully added"
  );

  const editValue =
    overrideKpiCode in roiOverrides?.[outcomeOverrideType]
      ? roiOverrides?.[outcomeOverrideType]?.[overrideKpiCode]
      : defaultValues[outcomeOverrideType];

  useLoadingState(
    saveDiscoveryROIOverrideLoadingState,
    () => {
      if (
        saveDiscoveryROIOverrideRequestParameters.outcomeOverrideType ===
        outcomeOverrideType
      ) {
        stopROIOverrideOperation();
        Notification.success(roiOverrideSaveSuccess);
      }
    },
    () => {
      if (
        saveDiscoveryROIOverrideRequestParameters.outcomeOverrideType ===
        outcomeOverrideType
      ) {
        stopROIOverrideOperation();
        Notification.error(roiOverrideSaveFailure);
      }
    }
  );

  const handleCancel = () => {
    stopROIOverrideOperation();
  };

  const onFinish = ({ kpiCode, inputValue }) => {
    saveDiscoveryROIOverride({
      discoveryId,
      outcomeOverrideType,
      kpiCode,
      value: inputValue
    });
  };

  const isSaveDisabled = ({ kpiCode, inputValue }) => {
    if (!kpiCode) {
      return true;
    }

    if (overrideIsEdit) {
      return (
        _.isEqual(
          inputValue,
          roiOverrides?.[outcomeOverrideType]?.[overrideKpiCode]
        ) || _.isEqual(inputValue, defaultValues[outcomeOverrideType])
      );
    }

    return _.isEqual(inputValue, defaultValues[outcomeOverrideType]);
  };

  const onFieldsChange = () => {
    setSaveDisabled(isSaveDisabled(form.getFieldsValue(true)));
  };

  return (
    <FormContainer>
      <Form
        form={form}
        name="outcomeOverrideForm"
        initialValues={{ kpiCode: overrideKpiCode, inputValue: editValue }}
        onFieldsChange={onFieldsChange}
        onFinish={onFinish}
        layout={"vertical"}
      >
        <ComponentContainer marginBottom={"20px"}>
          <StyledText
            type={"bMedium"}
            fontWeight={400}
            data-cy={"outcome-override-modal-description"}
          >
            {descriptionLabel}
          </StyledText>
        </ComponentContainer>
        <ComponentContainer>
          <StyledText type={"bMedium"} fontWeight={600}>
            {outcomeLabel}
          </StyledText>
        </ComponentContainer>
        <FormItem name="kpiCode">
          <SelectOutcomesOverride disabled={overrideIsEdit} />
        </FormItem>
        <ComponentContainer>
          <StyledText type={"bMedium"} fontWeight={600}>
            {inputLabel}
          </StyledText>
        </ComponentContainer>
        {outcomeOverrideType ===
          OutcomeOverrideTypes.BENEFIT_ADJUSTMENT_PER_YEAR && (
          <ListOfPercentageValuesScroll>
            <FormItem
              name="inputValue"
              rules={[
                {
                  required: true,
                  type: "array",
                  min: overrideEvaluationPeriod,
                  message: benefitAdjustmentValidation
                }
              ]}
            >
              <ListOfPercentageValues
                currency={discovery.currency}
                length={overrideEvaluationPeriod}
                disabled={!canUpdateROIValues}
                inputWidth={overrideEvaluationPeriod > 5 ? "70px" : "95px"}
                dataCy={"benefitsAdjustmentByYear"}
              />
            </FormItem>
          </ListOfPercentageValuesScroll>
        )}
        {outcomeOverrideType === OutcomeOverrideTypes.IMPLEMENTATION_LENGTH && (
          <FormItem
            name="inputValue"
            rules={[
              {
                required: true,
                type: "integer",
                message:
                  "Implementation Length cannot be negative or alphabetical",
                min: implementationLengthMin,
                max: implementationLengthMax
              }
            ]}
          >
            <SliderInput
              min={implementationLengthMin}
              max={implementationLengthMax}
              disabled={!canUpdateROIValues}
              marks={isMobile ? implementationLengthMarks : undefined}
              size={"medium"}
              width={440}
            />
          </FormItem>
        )}
        {outcomeOverrideType === OutcomeOverrideTypes.ADOPTION_TIME && (
          <FormItem
            name="inputValue"
            rules={[
              {
                required: true,
                type: "integer",
                message:
                  "Implementation Length cannot be negative or alphabetical",
                min: adoptionTimeMin,
                max: adoptionTimeMax
              }
            ]}
          >
            <SliderInput
              min={adoptionTimeMin}
              max={adoptionTimeMax}
              disabled={!canUpdateROIValues}
              marks={isMobile ? adoptionTimeMarks : undefined}
              size={"medium"}
              width={430}
            />
          </FormItem>
        )}

        <StyledButtonGroup>
          <NewButton
            type={"submit"}
            data-cy={"save-override-button"}
            disabled={saveDisabled}
            tooltip={saveDisabled ? disabledSaveButtonTooltip : undefined}
          >
            {saveLabel}
          </NewButton>
          <NewButton
            type={"secondary"}
            onClick={handleCancel}
            data-cy={"cancel-override-button"}
          >
            {cancelLabel}
          </NewButton>
        </StyledButtonGroup>
      </Form>
    </FormContainer>
  );
};

const ComponentContainer = styled.div`
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : undefined};
`;
const StyledText = styled(Text)`
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : undefined)};
`;

const StyledButtonGroup = styled(ButtonGroup)`
  margin-top: 20px;
`;

const FormContainer = styled.div`
  .slider-input-container {
    margin-top: -20px;

    @media screen and (max-width: 726px) {
      margin-top: 0px;
      width: 100%;
      gap: 16px;
    }
  }

  .slider-input-wrapper {
    z-index: 1;

    @media screen and (max-width: 726px) {
      margin-left: 0px;
    }
  }

  .input-group-container span {
    z-index: 1;
  }
`;

const ListOfPercentageValuesScroll = styled.div`
  overflow-x: auto;
`;

const mapStateToProps = (state) => ({
  discoveryId: selectDiscoveryId(state),
  discovery: selectDiscovery(state),
  roiOverrides: selectDiscoveryRoiOverrides(state),
  canUpdateROIValues: hasPermission(state, Permissions.UPDATE_ROI_VALUES),
  discoveryKPIs: selectDiscoveryKPIs(state),
  overrideKpiCode: selectROIOverrideKpiCode(state),
  overrideIsEdit: selectROIOverrideIsEdit(state),
  overrideEvaluationPeriod: selectROIFormValues(state)?.evaluationPeriod,
  overrideBenefitAdjustmentPerYear:
    selectROIFormValues(state)?.benefitAdjustmentPerYear,
  overrideAdoptionTime: selectROIFormValues(state)?.adoptionTime,
  overrideImplementationLength:
    selectROIFormValues(state)?.implementationLength,
  outcomeOverrideTypeClicked: selectROIOverrideType(state),
  saveDiscoveryROIOverrideLoadingState: selectRequestState(
    state,
    actionTypes.DISCOVERY_SAVE_ROI_OVERRIDE_REQUEST
  ),
  saveDiscoveryROIOverrideRequestParameters: selectRequestParameters(
    state,
    actionTypes.DISCOVERY_SAVE_ROI_OVERRIDE_REQUEST
  )
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveDiscoveryROIOverride,
      stopROIOverrideOperation
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  OutcomeOverrideForm
);
