import styled from "styled-components";
import PropType from "prop-types";
import EstimationScenarioTypes from "../../../utils/estimation-scenario-types";
import { Space } from "antd";
import Text from "../../Text";
import { useEffect, useRef, useState } from "react";
import {
  selectDiscoveryId,
  selectDiscoveryPhase,
  selectRequestData,
  selectRequestState
} from "../../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import {
  calculateDiscoveryKPIFormula,
  calculateDiscoveryKPIFormulaByReportId
} from "../../../store/actions/discoveries";
import actionTypes from "../../../store/actionTypes";
import LoadingState from "../../../utils/loading-state";
import { useString as s } from "../../StringProvider";
import { themeProp } from "../../../theme";
import { Phases } from "../../../utils/phases";
import Notification from "../../Notification";

const FormulaPanel = ({
  estimationScenario,
  discoveryKPI,
  visible,
  onClose,
  discoveryId,
  reportId,
  phase,
  loadingState,
  formulaDescription,
  calculateDiscoveryKPIFormula,
  calculateDiscoveryKPIFormulaByReportId
}) => {
  const ref = useRef(null);
  const [inProgress, setInProgress] = useState(false);
  const errorText = s(
    "formula.popup.messages.loadFormulaError",
    "Failed to load formula"
  );
  const warningText = s(
    "formula.popup.warning",
    "The following uses industry average numbers which can be personalised at the next step in the Discovery."
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClose();
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  useEffect(() => {
    if (visible) {
      if (reportId) {
        calculateDiscoveryKPIFormulaByReportId({
          reportId,
          kpiCode: discoveryKPI.kpiCode,
          estimationScenario
        });
      } else {
        calculateDiscoveryKPIFormula({
          discoveryId,
          kpiCode: discoveryKPI.kpiCode,
          estimationScenario
        });
      }
    }
  }, [visible]);

  useEffect(() => {
    if (visible) {
      switch (loadingState) {
        case LoadingState.IN_PROGRESS:
          setInProgress(true);
          break;

        case LoadingState.COMPLETED:
          if (inProgress) {
            setInProgress(false);
          }
          break;

        case LoadingState.FAILED:
          if (inProgress) {
            setInProgress(false);
            Notification.error(errorText);
            onClose();
          }
          break;
      }
    }
  }, [loadingState, visible]);

  return visible && loadingState === LoadingState.COMPLETED ? (
    <Container ref={ref}>
      <Space direction={"vertical"} size={8}>
        <Text variant={"bMedium"}>{discoveryKPI.definition.name}</Text>
        {phase === Phases.KPIS_IMPACTED ||
        phase === Phases.CHALLENGES_SOLVED ? (
          <WarningBox>{warningText}</WarningBox>
        ) : null}
        <FormulaText>
          {formulaDescription.map((item, index) => (
            <Text
              key={index}
              variant={item.type === "formula" ? "bMedium" : "body"}
              style={{ whiteSpace: "pre-line" }}
            >
              {item.text}{" "}
            </Text>
          ))}
        </FormulaText>
      </Space>
    </Container>
  ) : null;
};

const WarningBox = styled.div`
  padding: 8px;
  background-color: ${themeProp("palette.gray1")};
  border-radius: 4px;
  max-width: 450px;
`;

const FormulaText = styled.div`
  max-width: 450px;
  text-align: left;
`;

const Container = styled.div`
  padding: 24px;
`;

FormulaPanel.propTypes = {
  visible: PropType.bool.isRequired,
  estimationScenario: PropType.oneOf(EstimationScenarioTypes.ALL),
  discoveryKPI: PropType.object.isRequired,
  onClose: PropType.func.isRequired
};

const mapStateToProps = (state) => {
  return {
    discoveryId: selectDiscoveryId(state),
    loadingState: selectRequestState(
      state,
      actionTypes.DISCOVERY_CALCULATE_KPI_FORMULA_REQUEST
    ),
    formulaDescription: selectRequestData(
      state,
      actionTypes.DISCOVERY_CALCULATE_KPI_FORMULA_REQUEST
    ),
    phase: selectDiscoveryPhase(state)
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      calculateDiscoveryKPIFormula,
      calculateDiscoveryKPIFormulaByReportId
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  FormulaPanel
);
