import React, { useState, useEffect, useMemo } from "react";
import { themeProp } from "../../../theme";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useString as s } from "../../StringProvider";
import Form, { FormItem, FormItemLabel } from "../../Form";
import { Paragraph, TruncatedParagraph } from "../../../components/Typography";
import NewButton from "../../NewButton";
import Icon from "../../Icon";
import CheckboxGroup from "../../CheckboxGroup";
import Checkbox from "../../Checkbox";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import {
  hasPermission,
  selectDiscovery,
  selectDiscoveryId,
  selectRequestState
} from "../../../store/reducers";
import { getUniqueLinks } from "../ShareDiscoveryModal/functions";
import { getDiscoveryEmailContent } from "../../../store/actions/discoveries";
import actionTypes from "../../../store/actionTypes";
import MultipleEmailInput, {
  allEmailsValid,
  getEmailOptions,
  getValidEmails
} from "../../Input/MultipleEmailInput";
import Permissions from "../../../utils/permissions";
import Radio from "../../Radio";
import useLoadingState from "../../../utils/use-loading-state";
import Hyperlink from "../../Hyperlink";
import SelectExportTemplate from "../SelectExportTemplate";
import { Phases } from "../../../utils/phases";
import AccessTypes from "../ShareDiscoveryModal/access-types";
import Notification from "../../Notification";

const EMAIL_TEMPLATE_FORMAT = "email";

const getBodyTemplate = (template) => {
  switch (template) {
    case Phases.ROI:
      return "share.popup.template.roi.body";

    case Phases.VALUE_HYPOTHESIS:
      return "share.popup.template.valueHypothesis.body";

    case Phases.KPIS_IMPACTED:
      return "share.popup.template.kpis.body";

    case Phases.CHALLENGES_SOLVED:
      return "share.popup.template.challenges.body";
  }

  return template;
};

const EmailModalForm = ({
  setIsContentLoading,
  discovery,
  discoveryId,
  formValues,
  setFormValues,
  isContentGenerated,
  isContentLoading,
  setIsContentGenerated,
  getDiscoveryEmailContent,
  canShareEdit,
  loadingState
}) => {
  const { template } = formValues;

  const subjectTemplate = getBodyTemplate(template)?.replace(
    ".body",
    ".subject"
  );
  const bodyTemplate = getBodyTemplate(template);
  const templateLabel = s("email.popup.template.label", "EMAIL TEMPLATE");
  const templateTooltip = s(
    "email.popup.template.tooltip",
    "This determines what content we generate"
  );
  const resourcesLabel = s(
    "email.popup.resources.label",
    "LINKS TO ADDITIONAL RESOURCES"
  );
  const resourcesTooltip = s(
    "email.popup.resources.tooltip",
    "Select the links to include in the email"
  );
  const noResourcesLinks = s(
    "email.popup.no.resources.links",
    "The selected Challenges and Outcomes do not contain any links to additional resources."
  );
  const shareAccessLabel = s("email.popup.share.access.label", "SHARE ACCESS");
  const shareAccessTooltip = s(
    "email.popup.share.access.tooltip",
    "View Access: only view the report. Edit Access: modify discovery challenges, outcomes, and inputs."
  );
  const generateButtonLabel = s(
    "email.popup.generate.button.label",
    "Generate content"
  );
  const regenerateButtonLabel = s(
    "email.popup.regenerate.button.label",
    "Regenerate content"
  );
  const regenerateMessage = s(
    "email.popup.regenerate.message",
    "Manual edits will be replaced after Regenerate used."
  );
  const viewAccessReport = s(
    "email.popup.view.access.radio.button",
    "View access (Report)"
  );
  const editAccessReport = s(
    "email.popup.edit.access.radio.button",
    "Edit access (Discovery)"
  );
  const viewResourceTooltip = s(
    "email.popup.view.resource.button.tooltip",
    "View resource"
  );
  const emailPlaceholder = s("email.popup.email.placeholder", "Enter email(s)");
  const emailValidation = s(
    "email.popup.email.validation",
    "Please enter a valid email"
  );
  const emailGenerationFailed = s(
    "email.popup.email.generation.failed",
    "Failed to generate email"
  );

  const [form] = Form.useForm();
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(true);
  const [resourceslinks, setResourcesLinks] = useState([]);
  const [canGenerateContent, setCanGenerateContent] = useState(false);
  const [canRegenerate, setCanRegenerate] = useState(false);
  const [hasInvalidEmails, setHasInvalidEmails] = useState(false);

  const resourcesLinksOptions = useMemo(
    () => resourceslinks.map((link) => link.text),
    [resourceslinks]
  );

  useEffect(() => {
    setFormValues((prev) => ({ ...prev, shareAccess: viewAccessReport }));
  }, []);

  useEffect(() => {
    if (checkAll) {
      onCheckAllResourcesOptions({ target: { checked: true } });
    }
  }, [checkAll, resourcesLinksOptions]);

  useEffect(() => {
    if (formValues?.template) {
      setResourcesLinks(getUniqueLinks(discovery, formValues.template));
    }
  }, [discovery, formValues.template]);

  useEffect(() => {
    form.setFieldsValue(formValues);

    setIndeterminate(
      !!formValues.selectedLinks.length &&
        formValues.selectedLinks.length < resourcesLinksOptions.length
    );

    const isFormValidOnEditAccess =
      formValues.template !== "" &&
      formValues.shareAccess !== "" &&
      !hasInvalidEmails &&
      formValues.editAccessEmails.length > 0;

    const isFormValidOnShareAccess =
      formValues.template !== "" &&
      formValues.shareAccess !== "" &&
      !hasInvalidEmails;

    if (formValues.shareAccess === viewAccessReport) {
      setCanGenerateContent(isFormValidOnShareAccess);
    } else if (formValues.shareAccess === editAccessReport) {
      setCanGenerateContent(isFormValidOnEditAccess);
    }

    if (isContentGenerated) {
      if (formValues.shareAccess === viewAccessReport) {
        setCanRegenerate(isFormValidOnShareAccess);
      } else if (formValues.shareAccess === editAccessReport) {
        setCanRegenerate(isFormValidOnEditAccess);
      }
    }

    if (resourcesLinksOptions.length)
      setCheckAll(
        formValues.selectedLinks.length === resourcesLinksOptions.length
      );
  }, [form, formValues, hasInvalidEmails, resourcesLinksOptions]);

  const onValuesChange = (values) => {
    if (values.hasOwnProperty("template")) {
      setFormValues((prev) => ({ ...prev, template: values.template }));
    }

    if (values.hasOwnProperty("selectedLinks")) {
      setFormValues((prev) => ({
        ...prev,
        selectedLinks: values.selectedLinks
      }));
    }

    if (values.hasOwnProperty("shareAccess")) {
      setFormValues((prev) => ({ ...prev, shareAccess: values.shareAccess }));
    }

    if (values.hasOwnProperty("editAccessEmails")) {
      const emails = getEmailOptions(values.editAccessEmails);
      const hasInvalidEmails = !allEmailsValid(values.editAccessEmails);
      setHasInvalidEmails(hasInvalidEmails);

      if (isContentGenerated) setCanRegenerate(!hasInvalidEmails);

      setFormValues((prev) => ({
        ...prev,
        editAccessEmails: emails
      }));
    }
  };

  const onCheckAllResourcesOptions = (e) => {
    form.setFieldsValue({
      selectedLinks: e.target.checked ? resourcesLinksOptions : []
    });
    setIndeterminate(false);
    setCheckAll(e.target.checked);
    onValuesChange({
      selectedLinks: e.target.checked ? resourcesLinksOptions : []
    });
  };

  const onChangeResourcesOptions = (list) => {
    setIndeterminate(!!list.length && list.length < resourceslinks.length);
    setCheckAll(list.length === resourceslinks.length);
    onValuesChange({ selectedLinks: list });
  };

  const _getDiscoveryEmailContent = () => {
    const accessType =
      formValues.shareAccess === editAccessReport && canShareEdit
        ? AccessTypes.EDIT
        : AccessTypes.VIEW;

    const validEmails =
      accessType === AccessTypes.EDIT
        ? getValidEmails(formValues.editAccessEmails).map(
            (email) => email.value
          )
        : undefined;

    getDiscoveryEmailContent({
      discoveryId,
      subjectTemplate,
      bodyTemplate,
      data: {
        name: "",
        selectedLinks: formValues.selectedLinks,
        accessType,
        emails: validEmails,
        template
      }
    });
  };

  const onGenerateContent = () => {
    setIsContentLoading(true);
    setIsContentGenerated(false);
    setCanRegenerate(false);

    _getDiscoveryEmailContent();
  };

  useLoadingState(
    loadingState,
    () => {},
    () => {
      Notification.error(emailGenerationFailed);
      setIsContentLoading(false);
    }
  );

  const emailTemplate = (
    <StyledFormItem
      label={
        <FormItemLabel
          label={templateLabel}
          tooltip={templateTooltip}
          bold={true}
          size={"medium"}
        />
      }
      name="template"
    >
      <StyledSelectExportTemplate
        format={EMAIL_TEMPLATE_FORMAT}
        discoveryId={discoveryId}
        data-cy={"select-email-template"}
      />
    </StyledFormItem>
  );

  const resourcesLinks = (
    <>
      {resourceslinks.length > 0 && (
        <SelectAllContainer>
          <Checkbox
            indeterminate={indeterminate}
            onChange={onCheckAllResourcesOptions}
            checked={checkAll}
            data-cy={"select-all-links-checkbox"}
          >
            <TruncatedParagraph
              color={"gray4"}
              variant={"body"}
              rows="1"
              style={{
                width: "260px"
              }}
            >
              Select all
            </TruncatedParagraph>
          </Checkbox>
        </SelectAllContainer>
      )}

      <StyledFormItem
        label={
          <FormItemLabel
            label={resourcesLabel}
            tooltip={resourcesTooltip}
            bold={true}
            size={"medium"}
          />
        }
        name="selectedLinks"
        className="select-form-item"
      >
        {resourceslinks.length > 0 ? (
          <StyledCheckboxGroup
            onChange={onChangeResourcesOptions}
            defaultValue={resourcesLinksOptions}
          >
            {resourceslinks.map((resource, index) => {
              return (
                <StyledCheckbox
                  key={index}
                  value={resource.text}
                  data-cy={`resource-link-${resource.text}`}
                >
                  <TruncatedParagraph
                    color={"gray4"}
                    variant={"body"}
                    rows="1"
                    fullText={resource.text}
                    style={{
                      width: "255px"
                    }}
                  >
                    {resource.text}
                  </TruncatedParagraph>
                  <Hyperlink to={resource.url} tooltip={viewResourceTooltip}>
                    <Icon name="blankLink" size="medium" />
                  </Hyperlink>
                </StyledCheckbox>
              );
            })}
          </StyledCheckboxGroup>
        ) : (
          <Paragraph color={"gray4"} variant={"small"}>
            {noResourcesLinks}
          </Paragraph>
        )}
      </StyledFormItem>
    </>
  );

  const shareAccess = (
    <StyledFormItem
      label={
        <FormItemLabel
          label={shareAccessLabel}
          tooltip={shareAccessTooltip}
          bold={true}
          size={"medium"}
        />
      }
      name="shareAccess"
    >
      <StyledRadioGroup>
        {[viewAccessReport, editAccessReport].map((access, index) => (
          <Radio
            value={access}
            key={index}
            disabled={access === editAccessReport ? !canShareEdit : false}
            data-cy={
              access === editAccessReport
                ? "edit-access-option-button"
                : "view-access-option-button"
            }
          >
            <Paragraph color={"gray4"} variant={"body"}>
              {access}
            </Paragraph>
          </Radio>
        ))}
      </StyledRadioGroup>
    </StyledFormItem>
  );

  const editAccessEmail = (
    <EmailContainer>
      {formValues.shareAccess === editAccessReport && (
        <StyledFormItem
          name="editAccessEmails"
          rules={[
            () => ({
              validator(_, value) {
                if (!allEmailsValid(value)) {
                  return Promise.reject(new Error(emailValidation));
                }

                return Promise.resolve();
              }
            })
          ]}
        >
          <MultipleEmailInput
            placeholder={emailPlaceholder}
            variant="secondary"
            maxTagRows={2}
          />
        </StyledFormItem>
      )}
    </EmailContainer>
  );

  return (
    <Container>
      <StyledForm
        layout="vertical"
        form={form}
        onValuesChange={onValuesChange}
        noResourcesLinks={resourceslinks.length}
      >
        <div>
          {emailTemplate}
          {resourcesLinks}
          {shareAccess}
          {canShareEdit && editAccessEmail}
        </div>
        <div>
          {canRegenerate && (
            <RegenerateMessage>
              <Icon name="infoCircle" />
              <Paragraph color={"gray4"} variant={"small"}>
                {regenerateMessage}
              </Paragraph>
            </RegenerateMessage>
          )}
          <StyledNewButton
            type="alternate"
            onClick={onGenerateContent}
            disabled={
              isContentLoading ||
              (isContentGenerated ? !canRegenerate : !canGenerateContent)
            }
            data-cy={"generate-content-button"}
          >
            {isContentGenerated ? regenerateButtonLabel : generateButtonLabel}
          </StyledNewButton>
        </div>
      </StyledForm>
    </Container>
  );
};

const Container = styled.div`
  width: 360px;
  padding: 16px;
  background-color: #fbfbfc;

  @media screen and (max-width: 754px) {
    width: 100%;
  }
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  & .ant-select-selection-item {
    height: 40px;
    display: flex;
    align-items: center;
  }

  .ant-select {
    height: 40px;
    margin-top: -10px;
  }

  .cHVCja {
    min-height: 0;
  }

  .select-form-item .ant-form-item-label {
    transform: ${(props) =>
      props.noResourcesLinks > 0 ? `translateY(-30px)` : `translateY(0px)`};
  }
`;

const StyledFormItem = styled(FormItem)`
  font-weight: bold;
  min-height: 0;
  margin-bottom: 16px;
`;

const StyledSelectExportTemplate = styled(SelectExportTemplate)`
  .ant-select-selector {
    min-height: 0 !important;
  }
`;

const StyledNewButton = styled(NewButton)`
  width: 100%;
`;

const StyledRadioGroup = styled(Radio.Group)`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const StyledCheckboxGroup = styled(CheckboxGroup)`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 8px;
  max-height: 165px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  scrollbar-width: thin;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: ${themeProp("palette.gray4")};
  }
`;

const SelectAllContainer = styled.div`
  margin-bottom: 12px;
  transform: translateY(30px);
`;

const StyledCheckbox = styled(Checkbox)`
  span {
    display: inline-flex;
    flex-wrap: nowrap;
  }
`;

const RegenerateMessage = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
`;

const EmailContainer = styled.div`
  margin-top: 8px;
`;

EmailModalForm.propTypes = {
  isContentGenerated: PropTypes.bool.isRequired,
  setIsContentGenerated: PropTypes.func.isRequired,
  isContentLoading: PropTypes.bool.isRequired,
  setIsContentLoading: PropTypes.func.isRequired
};

FormItemLabel.propTypes = {
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getDiscoveryEmailContent
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  discoveryId: selectDiscoveryId(state),
  discovery: selectDiscovery(state),
  canShareEdit: hasPermission(state, Permissions.SHARE_EDIT),
  loadingState: selectRequestState(
    state,
    actionTypes.DISCOVERY_GENERATE_EMAIL_REQUEST
  )
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  EmailModalForm
);
