import JobMonitor from "../../../../components/JobMonitor";
import React, { useEffect, useState } from "react";
import JobStatuses from "../../../../utils/job-statuses";
import { useString as s } from "../../../../components/StringProvider";
import { Space, Spin } from "antd";
import openDownload from "../../../../utils/open-download";
import Heading from "../../../../components/Heading";
import Text from "../../../../components/Text";
import { useTheme } from "../../../../components/ThemeProvider";
import styled from "styled-components";
import PropTypes from "prop-types";
import { LoadingOutlined } from "@ant-design/icons";
import { Modal } from "../../../../components";
import Notification from "../../../../components/Notification";

const DownloadFileModal = ({ jobId }) => {
  const [job, setJob] = useState(null);
  const header = s(
    "organization.page.currentConfiguration.downloadFileModal.header",
    "Preparing File for Download"
  );
  const subheader = "";
  const failedToDownload = s(
    "organization.page.currentConfiguration.messages.failedToDownload",
    "Failed to download file"
  );
  const successColor = useTheme("palette.primary");
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: successColor }} spin />
  );
  useEffect(() => {
    switch (job?.status) {
      case JobStatuses.FAILED:
        Notification.error(failedToDownload);
        break;
      case JobStatuses.COMPLETED:
        openDownload(job.results.url);
        break;
    }
  }, [job]);

  return (
    <>
      {job?.status !== JobStatuses.FAILED &&
        job?.status !== JobStatuses.COMPLETED && (
          <Modal open={true} closable={false} width={400}>
            <StyledSpace direction={"vertical"} size={24} align={"center"}>
              <Heading level={"h3"}>{header}</Heading>
              <LoaderDiv>
                <Spin indicator={antIcon} size={"large"} />
              </LoaderDiv>
              <Text>{subheader}</Text>
            </StyledSpace>
          </Modal>
        )}
      <JobMonitor jobId={jobId} onChange={setJob} />
    </>
  );
};

const StyledSpace = styled(Space)`
  width: 100%;

  & h3 {
    margin: 0;
  }
`;

const LoaderDiv = styled.div`
  & .ant-spin.ant-spin-lg,
  & .ant-spin.ant-spin-lg .anticon {
    font-size: 48px !important;
  }
`;

DownloadFileModal.propTypes = {
  jobId: PropTypes.string.isRequired
};

export default DownloadFileModal;
