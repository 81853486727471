import PropTypes from "prop-types";
import DescriptionList from "../../../../components/DescriptionList";
import Text from "../../../../components/Text";
import styled from "styled-components";
import { useContext } from "react";
import { SettingsContext } from "../../../../components/SettingsProvider";
import { useString as s } from "../../../../components/StringProvider";
import { formatConfigurationDetailDate } from "../../../../utils/formatting";
import ButtonGroup from "../../../../components/ButtonGroup";
import NewButton from "../../../../components/NewButton";
import {
  selectRequestData,
  selectRequestState
} from "../../../../store/reducers";
import actionTypes from "../../../../store/actionTypes";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { exportValidation } from "../../../../store/actions/validations";
import useLoadingState from "../../../../utils/use-loading-state";
import openDownload from "../../../../utils/open-download";
import ValidationExportTypes from "../../../../utils/validation-export-types";
import Icon from "../../../../components/Icon";
import DownloadMasterDataButton from "./DownloadMasterDataButton";
import Notification from "../../../../components/Notification";

const MasterDataDetails = ({
  log,
  exportLoadingState,
  exportFile,
  exportValidation
}) => {
  const { settings } = useContext(SettingsContext);
  const { locale } = settings;
  const lastUpdated = s(
    "organization.page.configurationLogs.details.lastUpdated",
    "Last Updated"
  );
  const updatedBy = s(
    "organization.page.configurationLogs.details.updatedBy",
    "Updated By"
  );
  const createdByDefault = s(
    "accessManagement.page.roles.createdBy.default",
    "Cuvama"
  );
  const mdModel = s(
    "organization.page.configurationLogs.details.mdModel",
    "MD Model"
  );
  const downloadErrors = s(
    "organization.page.configurationLogs.details.downloadErrors",
    "Error Log"
  );
  const downloadErrorsFailed = s(
    "organization.page.configurationLogs.details.messages.downloadErrorsFailed",
    "Failed to download errors"
  );

  useLoadingState(
    exportLoadingState,
    () => {
      openDownload(exportFile.url);
    },
    () => {
      Notification.error(downloadErrorsFailed);
    }
  );

  return (
    <Container>
      <div>
        <DescriptionList>
          <dt>{lastUpdated}</dt>
          <dd>
            {formatConfigurationDetailDate({ time: log.createdAt, locale })}
          </dd>
          <dt>{updatedBy}</dt>
          <dd>{log.username || createdByDefault}</dd>
          <dt>{mdModel}</dt>
          <dd>{log.templateVersion}</dd>
        </DescriptionList>
        <Text>{log.description}</Text>
      </div>
      <BottomDiv>
        <ButtonGroup>
          {log?.fileId && (
            <DownloadMasterDataButton
              type={"primaryDropdown"}
              fileId={log.fileId}
            />
          )}
          {log?.validationId && log.status === "validationFailed" && (
            <NewButton
              type={"secondaryDropdown"}
              onClick={() =>
                exportValidation({
                  validationId: log.validationId,
                  type: ValidationExportTypes.ERROR_LOG
                })
              }
            >
              {downloadErrors} <Icon name={"download"} />
            </NewButton>
          )}
        </ButtonGroup>
      </BottomDiv>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  & h4 {
    margin-bottom: 8px;
  }
`;

const BottomDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;
`;

MasterDataDetails.propTypes = {
  log: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  exportLoadingState: selectRequestState(
    state,
    actionTypes.EXPORT_VALIDATION_REQUEST
  ),
  exportFile: selectRequestData(state, actionTypes.EXPORT_VALIDATION_REQUEST)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      exportValidation
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  MasterDataDetails
);
