import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Form, { FormItem } from "../Form";
import { RichTextEditor } from "../Input";
import { useString as s } from "../StringProvider";
import ButtonGroup from "../ButtonGroup";
import NewButton from "../NewButton";
import { useWindowDimensions } from "../Responsive";
import {
  startDiscoveryNoteSession,
  stopDiscoveryNoteSession,
  updateDiscoveryNote,
  keepDiscoveryNoteSessionAlive
} from "../../store/actions/notes";
import {
  selectRequestData,
  selectRequestError,
  selectRequestState,
  selectUser
} from "../../store/reducers";
import actionTypes from "../../store/actionTypes";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import _ from "lodash";
import useLoadingState from "../../utils/use-loading-state";
import { themeProp } from "../../theme";
import Text from "../Text";
import Notification from "../Notification";

const NOTES_EDITOR_DEBOUNCE_TIMEOUT = 2000;
const BUTTON_GROUP_HEIGHT = 48 + 41 + 24 + 57 - 11 - 10;
const BUTTON_GROUP_HEIGHT_MOBILE = BUTTON_GROUP_HEIGHT + 55;
const sessionKeepAliveMinutes = 2.5 * 60 * 1000;

const EditorState = {
  SAVING: "SAVING",
  SAVED: "SAVED"
};

const NotesEditor = ({
  closeDrawer,
  updateDiscoveryNote,
  stopDiscoveryNoteSession,
  discoveryNote,
  notesTemplate,
  keepDiscoveryNoteSessionAlive,
  startDiscoveryNoteSession,
  startSessionLoadingState,
  startSessionError,
  keepSessionAliveLoadingState,
  keepSessionAliveError,
  updateNoteLoadingState,
  updateNoteLoadingError,
  stopSessionLoadingState
}) => {
  const [form] = Form.useForm();
  const [editorState, setEditorState] = useState("");
  const [lockInitialized, setLockInitialized] = useState(false);
  const [readOnly, setReadOnly] = useState(true);
  const [username, setUsername] = useState("");
  const [intervalId, setIntervalId] = useState(-1);
  const [doneClicked, setDoneClicked] = useState(false);
  const [sessionActive, setSessionActive] = useState(false);
  const location = useLocation();
  const isNewWindow = location.pathname.includes("/notes");
  const params = useParams();
  const discoveryId = params.discoveryId;
  const { height } = useWindowDimensions();
  const saveText = s("notes.text.save", "Done!");
  const savingText = s("notes.text.saving", "Saving...");
  const savedText = s("notes.text.saved", "Saved");
  const startSessionFailed = s(
    "notes.messages.startSessionFailed",
    "Failed to lock notes"
  );
  const updateNoteFailed = s(
    "notes.messages.updateNoteFailed",
    "Failed to save notes"
  );
  const lockedText = s(
    "notes.text.locked",
    "{username} is editing this note. You're viewing an outdated version. Try opening it later to edit.",
    {
      username
    }
  );
  const handleLockedError = (e) => {
    console.log("handleLockedError", typeof e?.data, e?.data);
    setUsername(e?.data?.data?.username);
    setReadOnly(true);
  };

  useEffect(() => {
    startDiscoveryNoteSession({ discoveryId });
  }, []);

  useLoadingState(
    startSessionLoadingState,
    () => {
      setReadOnly(false);
      restartKeepSessionAlive();
      setLockInitialized(true);
      setSessionActive(true);
    },
    () => {
      if (startSessionError && startSessionError.status === 423) {
        handleLockedError(startSessionError);
      } else {
        Notification.error(startSessionFailed);
      }

      setLockInitialized(true);
      setReadOnly(true);
    }
  );

  useLoadingState(
    keepSessionAliveLoadingState,
    () => {
      restartKeepSessionAlive();
    },
    () => {
      if (startSessionError && startSessionError.status === 423) {
        handleLockedError(keepSessionAliveError);
      } else {
        Notification.error(startSessionFailed);
      }
    }
  );

  useLoadingState(
    updateNoteLoadingState,
    () => {},
    () => {
      if (updateNoteLoadingError && updateNoteLoadingError.status === 423) {
        handleLockedError(updateNoteLoadingError);
      } else {
        Notification.error(updateNoteFailed);
      }

      setReadOnly(true);
    }
  );

  useLoadingState(
    stopSessionLoadingState,
    () => {
      setSessionActive(false);
    },
    () => {
      setSessionActive(false);
    }
  );

  const closeWindow = () => {
    window.opener = null;
    window.open("", "_self");
    window.close();
  };

  const stopSession = () => {
    stopDiscoveryNoteSession({
      discoveryId
    });
  };

  const keepSessionAlive = () => {
    keepDiscoveryNoteSessionAlive({ discoveryId });
  };

  const restartKeepSessionAlive = () => {
    if (intervalId) {
      clearInterval(intervalId);
    }
    const id = setInterval(keepSessionAlive, sessionKeepAliveMinutes);
    setIntervalId(id);
  };

  useEffect(() => {
    if (!sessionActive && doneClicked && editorState !== EditorState.SAVING) {
      if (isNewWindow && doneClicked) {
        closeWindow();
      } else if (!isNewWindow && doneClicked) {
        closeDrawer();
      }
      setDoneClicked(false);
    }
  }, [sessionActive, doneClicked, editorState]);

  const _updateDiscoveryNote = ({ discoveryId, userSessionId, text }) => {
    updateDiscoveryNote({ discoveryId, userSessionId, text });
  };

  const debounceSaveDiscovery = useCallback(
    _.debounce(_updateDiscoveryNote, NOTES_EDITOR_DEBOUNCE_TIMEOUT, {
      trailing: true
    }),
    []
  );

  const onCloseNoAction = () => {
    if (isNewWindow) {
      closeWindow();
    } else if (!isNewWindow) {
      closeDrawer();
    }
  };

  useLoadingState(
    updateNoteLoadingState,
    () => {
      setEditorState(EditorState.SAVED);
    },
    (error) => {
      if (error) {
        Notification.error(updateNoteFailed);
      }
    }
  );

  const onFinish = async () => {
    setDoneClicked(true);
    stopSession();
  };

  const onValuesChange = async (values) => {
    const text = values.text;
    setEditorState(EditorState.SAVING);
    debounceSaveDiscovery({
      discoveryId,
      text
    });
  };

  const renderEditorState = () => {
    switch (editorState) {
      case EditorState.SAVING:
        return savingText;

      case EditorState.SAVED:
        return savedText;

      default:
        return "";
    }
  };

  if (!discoveryNote) {
    return null;
  }

  return (
    lockInitialized && (
      <Container isDrawer={!isNewWindow}>
        {readOnly && username && (
          <NoteLockedContainer>
            <Text variant={"small"}>{lockedText}</Text>
          </NoteLockedContainer>
        )}
        {!readOnly && (
          <SavingContainer>
            <Text color={"gray4"} variant={"small"}>
              {renderEditorState()}
            </Text>
          </SavingContainer>
        )}
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            text:
              (discoveryNote && discoveryNote.text) ||
              (notesTemplate && notesTemplate.content)
          }}
          onFinish={onFinish}
          onValuesChange={onValuesChange}
        >
          <FormItem
            name="text"
            rules={[
              {
                whitespace: true
              }
            ]}
          >
            <RichTextEditor
              disabled={readOnly}
              autoFocus={true}
              placeholder={s("notes.text.placeholder", "Add notes here...")}
              style={{
                minHeight:
                  height -
                  (isNewWindow
                    ? BUTTON_GROUP_HEIGHT
                    : BUTTON_GROUP_HEIGHT_MOBILE) -
                  (readOnly ? 22 : 0),
                height:
                  height -
                  (isNewWindow
                    ? BUTTON_GROUP_HEIGHT
                    : BUTTON_GROUP_HEIGHT_MOBILE) -
                  (readOnly ? 22 : 0),
                maxHeight:
                  height - isNewWindow
                    ? BUTTON_GROUP_HEIGHT
                    : BUTTON_GROUP_HEIGHT_MOBILE - (readOnly ? 22 : 0)
              }}
            />
          </FormItem>
          {!readOnly && (
            <FormItem>
              <ButtonGroup>
                <NewButton type={"submit"}>{saveText}</NewButton>
              </ButtonGroup>
            </FormItem>
          )}
          {readOnly && (
            <FormItem>
              <ButtonGroup>
                <NewButton onClick={onCloseNoAction}>{saveText}</NewButton>
              </ButtonGroup>
            </FormItem>
          )}
        </Form>
      </Container>
    )
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 24px 24px 24px;
  min-height: ${({ isDrawer }) => (isDrawer ? "calc(100vh  - 55px)" : "100vh")};
  max-height: ${({ isDrawer }) => (isDrawer ? "calc(100vh  - 55px)" : "100vh")};
  background: ${themeProp("palette.surface")};

  & form.ant-form {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    & .ant-form-item:first-child {
      flex-grow: 1;
    }
  }
`;

const SavingContainer = styled.div`
  min-height: 30px;
  margin-bottom: 16px;
`;

const NoteLockedContainer = styled.div`
  font-size: 14px;
  background-color: rgb(245, 117, 24, 0.15);
  border-radius: 5px;
  padding: 4px 10px 4px 10px;
  margin-bottom: 16px;
`;

const mapStateToProps = (state) => ({
  discoveryNote: selectRequestData(state, actionTypes.GET_NOTE_REQUEST),
  notesTemplate: selectRequestData(
    state,
    actionTypes.GET_NOTES_TEMPLATE_REQUEST
  ),
  user: selectUser(state),
  updateNoteLoadingState: selectRequestState(
    state,
    actionTypes.UPDATE_NOTE_REQUEST
  ),
  updateNoteError: selectRequestError(state, actionTypes.UPDATE_NOTE_REQUEST),
  startSessionLoadingState: selectRequestState(
    state,
    actionTypes.START_NOTE_SESSION_REQUEST
  ),
  startSessionError: selectRequestError(
    state,
    actionTypes.START_NOTE_SESSION_REQUEST
  ),
  keepSessionAliveLoadingState: selectRequestState(
    state,
    actionTypes.KEEP_NOTE_SESSION_ALIVE_REQUEST
  ),
  keepSessionAliveError: selectRequestError(
    state,
    actionTypes.KEEP_NOTE_SESSION_ALIVE_REQUEST
  ),
  stopSessionLoadingState: selectRequestState(
    state,
    actionTypes.STOP_NOTE_SESSION_REQUEST
  )
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateDiscoveryNote,
      startDiscoveryNoteSession,
      stopDiscoveryNoteSession,
      keepDiscoveryNoteSessionAlive
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  NotesEditor
);
