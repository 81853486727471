import React, { useEffect } from "react";
import ConfirmationCodeForm from "./ConfirmationCodeForm";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useTheme } from "../../components/ThemeProvider";
import { SplitPageLayout } from "../../components/Layout";
import Page from "../../components/Page";
import {
  selectRegisteredUsername,
  selectRequestState
} from "../../store/reducers";
import {
  confirmSignUp,
  resendSignUp
} from "../../store/actions/authentication";
import actionTypes from "../../store/actionTypes";
import useLoadingState from "../../utils/use-loading-state";
import { useString as s } from "../../components/StringProvider";
import Notification from "../../components/Notification";

const ConfirmationCode = ({
  history,
  loadingState,
  resendLoadingState,
  registeredUsername,
  confirmSignUp,
  resendSignUp
}) => {
  const resendSuccessful = s(
    "confirmation_code.page.messages.resendCode",
    "Code resent."
  );
  const resendFailed = s(
    "confirmation_code.page.messages.resendFailed",
    "Failed to resend code."
  );

  useEffect(() => {
    if (!registeredUsername) {
      history.push("/login");
    }
  }, [registeredUsername]);

  useLoadingState(loadingState, () => {
    history.push("/login");
  });

  useLoadingState(
    resendLoadingState,
    () => {
      Notification.success(resendSuccessful);
    },
    () => {
      Notification.error(resendFailed);
    }
  );

  const onFinish = ({ verificationCode }) => {
    confirmSignUp({
      email: registeredUsername,
      confirmationCode: verificationCode
    });
  };

  const onResendCode = () => {
    resendSignUp({ username: registeredUsername });
  };

  return (
    <Page>
      <SplitPageLayout image={useTheme("assets.signup_cover")}>
        <ConfirmationCodeForm onFinish={onFinish} onResendCode={onResendCode} />
      </SplitPageLayout>
    </Page>
  );
};

function mapStateToProps(state) {
  return {
    loadingState: selectRequestState(state, actionTypes.CONFIRM_USER_REQUEST),
    registeredUsername: selectRegisteredUsername(state),
    resendLoadingState: selectRequestState(
      state,
      actionTypes.RESEND_CONFIRMATION_REQUEST
    )
  };
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      confirmSignUp,
      resendSignUp
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ConfirmationCode);
