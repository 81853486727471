import { bindActionCreators, compose } from "redux";
import {
  alertDiscoveryOperation,
  confirmDiscoveryOperation
} from "../../store/actions/discovery-operations";
import { connect } from "react-redux";
import {
  selectDiscoveryOperationAlert,
  selectDiscoveryOperationConfirmation,
  selectDiscoveryOperationResults
} from "../../store/reducers";
import { useEffect } from "react";
import types from "../../store/actionTypes";
import { useString as s } from "../StringProvider";
import _ from "lodash";
import Notification from "../Notification";

const DiscoveryOperationsConfirmation = ({
  alert,
  confirmation,
  alertDiscoveryOperation,
  confirmDiscoveryOperation,
  results
}) => {
  const confirmationMessages = {};
  const alertMessages = {};
  confirmationMessages[types.ASSIGN_DISCOVERY_SUCCESS] = s(
    "confirmation.ASSIGN_DISCOVERY_SUCCESS",
    "Discovery was assigned to {email}",
    { email: _.get(results, "discovery.username", "") }
  );
  confirmationMessages[types.DELETE_COMMENT_SUCCESS] = s(
    "confirmation.DELETE_COMMENT_SUCCESS",
    "Comment was removed"
  );
  alertMessages[types.ASSIGN_DISCOVERY_FAILURE] = s(
    "error.ASSIGN_DISCOVERY_FAILURE",
    "Failed to assign discovery"
  );
  alertMessages[types.GET_COMMENTS_FAILURE] = s(
    "error.GET_COMMENTS_FAILURE",
    "Failed to get comments"
  );
  alertMessages[types.POST_COMMENT_FAILURE] = s(
    "error.POST_COMMENT_FAILURE",
    "Failed to post comment"
  );
  alertMessages[types.UPDATE_COMMENT_FAILURE] = s(
    "error.UPDATE_COMMENT_FAILURE",
    "Failed to update comment"
  );

  useEffect(() => {
    if (confirmation && confirmationMessages[confirmation]) {
      Notification.info(confirmationMessages[confirmation]);
      confirmDiscoveryOperation();
    }
  }, [confirmation, confirmationMessages, confirmDiscoveryOperation]);

  useEffect(() => {
    if (alert && alertMessages[alert]) {
      Notification.error(alertMessages[alert]);
      alertDiscoveryOperation();
    }
  }, [alert, alertMessages, alertDiscoveryOperation]);

  return null;
};

const mapStateToProps = (state) => ({
  alert: selectDiscoveryOperationAlert(state),
  confirmation: selectDiscoveryOperationConfirmation(state),
  results: selectDiscoveryOperationResults(state)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      alertDiscoveryOperation,
      confirmDiscoveryOperation
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  DiscoveryOperationsConfirmation
);
