import Heading from "../../../../components/Heading";
import Text from "../../../../components/Text";
import { useString as s } from "../../../../components/StringProvider";
import styled from "styled-components";
import PropTypes from "prop-types";
import ConfigurationCardTypes from "./ConfigurationCardTypes";
import Table from "../../../../components/Table";
import { themeProp } from "../../../../theme";
import {
  hasPermission,
  selectRequestData,
  selectRequestState
} from "../../../../store/reducers";
import Permissions from "../../../../utils/permissions";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { hasSandbox } from "../../../../store/selectors/config";
import NewButton from "../../../../components/NewButton";
import Icon from "../../../../components/Icon";
import { downloadAllPowerpointFileDeployments } from "../../../../store/actions/deployments";
import actionTypes from "../../../../store/actionTypes";
import useLoadingState from "../../../../utils/use-loading-state";
import { useState } from "react";
import DownloadFileModal from "./DownloadFileModal";
import DownloadFileIconButton from "../ConfigurationLogs/DownloadFileIconButton";
import Notification from "../../../../components/Notification";

const ConfigurationPowerpointTemplates = ({
  powerpointTemplates,
  cardType,
  canUpdate,
  downloadAllPowerpointFileDeployments,
  downloadAllLoadingState,
  downloadAll
}) => {
  const header = s(
    "organization.page.currentConfiguration.powerpointTemplates.header",
    "PPT Templates"
  );
  const noTemplatesCanUpdate = s(
    "organization.page.currentConfiguration.powerpointTemplates.noTemplatesCanUpdate",
    'Nothing here yet. To add a template, click the "Update" button above.'
  );
  const noTemplates = s(
    "organization.page.currentConfiguration.powerpointTemplates.noTemplates",
    "Nothing here yet."
  );
  const name = s(
    "organization.page.currentConfiguration.powerpointTemplates.name",
    "Name"
  );
  const version = s(
    "organization.page.currentConfiguration.powerpointTemplates.version",
    "Version"
  );
  const lastUpdated = s(
    "organization.page.currentConfiguration.powerpointTemplates.lastUpdated",
    "Last Updated"
  );
  const updatedBy = s(
    "organization.page.currentConfiguration.powerpointTemplates.updatedBy",
    "Updated By"
  );
  const downloadAllLabel = s(
    "organization.page.currentConfiguration.powerpointTemplates.downloadAll",
    "Download All"
  );
  const createdByDefault = s(
    "accessManagement.page.roles.createdBy.default",
    "Cuvama"
  );
  const failedToDownload = s(
    "organization.page.currentConfiguration.powerpointTemplates.errorNotification",
    "Failed to download"
  );

  const [jobId, setJobId] = useState("");

  useLoadingState(
    downloadAllLoadingState,
    () => {
      setJobId(downloadAll._id);
    },
    () => {
      Notification.error(failedToDownload);
    }
  );

  const columns = [
    {
      title: name,
      dataIndex: "name",
      key: "name",
      className: "name",
      ellipsis: true
    },
    {
      title: version,
      dataIndex: "version",
      key: "version",
      className: "version",
      align: "right",
      width: "60px",
      render: (version) => `${version}.0`
    },
    {
      title: lastUpdated,
      dataIndex: "deployedAt",
      key: "deployedAt",
      className: "lastUpdated",
      align: "right",
      width: "120px",
      render: (deployedAt) => new Date(deployedAt).toDateString()
    },
    {
      title: updatedBy,
      dataIndex: "username",
      key: "username",
      className: "username",
      ellipsis: { showTitle: true },
      render: (username) => username || createdByDefault
    },
    {
      title: "",
      dataIndex: "fileId",
      key: "fileId",
      className: "download",
      width: "30px",
      render: (fileId) => <DownloadFileIconButton fileId={fileId} />
    }
  ];

  const DownloadAllButton = () => (
    <NewButton
      type={"text"}
      onClick={() => {
        downloadAllPowerpointFileDeployments();
      }}
    >
      <Icon name={"download"} /> {downloadAllLabel}
    </NewButton>
  );

  return (
    <Container>
      <TitleBar>
        <Heading level={"h4"}>{header}</Heading>
        {!!powerpointTemplates?.length && <DownloadAllButton />}
      </TitleBar>
      {powerpointTemplates.length ? (
        <StyledTable
          removeHeaderStyle={true}
          columns={columns}
          dataSource={powerpointTemplates.map((item, index) => ({
            ...item,
            key: index
          }))}
          pagination={false}
        />
      ) : (
        <Text variant={"small"} color={"gray4"}>
          {canUpdate && cardType === ConfigurationCardTypes.SANDBOX
            ? noTemplatesCanUpdate
            : noTemplates}
        </Text>
      )}
      {jobId && <DownloadFileModal key={jobId} jobId={jobId} />}
    </Container>
  );
};

const Container = styled.div``;
const TitleBar = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: baseline;
`;

const StyledTable = styled(Table)`
  & thead.ant-table-thead > tr > th.ant-table-cell::before {
    content: none !important;
  }

  & .ant-table-thead .ant-table-cell {
    font-family: ${themeProp("typography.small.fontFamily")};
    font-size: ${themeProp("typography.small.fontSize")} !important;
    font-weight: ${themeProp("typography.small.fontWeight")} !important;
    line-height: ${themeProp("typography.small.lineHeight")} !important;
    color: ${themeProp("palette.gray4")} !important;
    padding: 0 16px 4px 0 !important;
    background: none !important;
    border: none !important;
  }

  & .ant-table-tbody .ant-table-cell {
    font-family: ${themeProp("typography.small.fontFamily")};
    font-size: ${themeProp("typography.small.fontSize")} !important;
    font-weight: ${themeProp("typography.small.fontWeight")} !important;
    line-height: ${themeProp("typography.small.lineHeight")} !important;
    padding: 4px 16px 4px 0 !important;
    background: none;
    border: none;
    vertical-align: baseline;
  }

  & .ant-table-tbody .ant-table-cell.name {
    font-family: ${themeProp("typography.body.fontFamily")};
    font-size: ${themeProp("typography.body.fontSize")} !important;
    font-weight: ${themeProp("typography.body.fontWeight")} !important;
    line-height: ${themeProp("typography.body.lineHeight")} !important;
  }
`;

ConfigurationPowerpointTemplates.propTypes = {
  powerpointTemplates: PropTypes.array,
  cardType: PropTypes.oneOf(ConfigurationCardTypes.ALL)
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      downloadAllPowerpointFileDeployments
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  canUpdate:
    hasPermission(state, Permissions.UPDATE_CONFIGURATION) &&
    !hasSandbox(state),
  downloadAllLoadingState: selectRequestState(
    state,
    actionTypes.DOWNLOAD_ALL_FILE_DEPLOYMENTS_REQUEST
  ),
  downloadAll: selectRequestData(
    state,
    actionTypes.DOWNLOAD_ALL_FILE_DEPLOYMENTS_REQUEST
  )
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ConfigurationPowerpointTemplates
);
