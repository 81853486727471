import { selectRequestData, selectRequestState } from "../../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Form, { FormItem } from "../../../components/Form";
import Input from "../../../components/Input";
import ButtonGroup from "../../../components/ButtonGroup";
import NewButton from "../../../components/NewButton";
import { useString as s } from "../../../components/StringProvider";
import { useMobileMediaQuery } from "../../../components/Responsive";
import { createAutoconfiguration } from "../../../store/actions/autoconfigurations";
import actionTypes from "../../../store/actionTypes";
import useLoadingState from "../../../utils/use-loading-state";
import styled from "styled-components";
import { themeProp } from "../../../theme";
import Notification from "../../../components/Notification";

const CreateAutoconfiguration = ({
  createAutoconfiguration,
  loadingState,
  autoconfiguration,
  history
}) => {
  const [form] = Form.useForm();
  const isMobile = useMobileMediaQuery();
  const submitButtonText = s(
    "organization.page.autoconfiguration.createAutoconfiguration.form.submit.text",
    "Generate Master Data"
  );
  const error = s(
    "organization.page.autoconfiguration.createAutoconfiguration.messages.error",
    "Failed to generate master data"
  );
  const companyNameLabel = s(
    "organization.page.autoconfiguration.createAutoconfiguration.form.corporateName.label",
    "Company Name"
  );
  const companyNameMessage = s(
    "organization.page.autoconfiguration.createAutoconfiguration.form.corporateName.message",
    "Please enter you company name"
  );
  const companyNamePlaceholder = s(
    "organization.page.autoconfiguration.createAutoconfiguration.form.corporateName.placeholder",
    "Microsoft"
  );
  const companyWebsiteLabel = s(
    "organization.page.autoconfiguration.createAutoconfiguration.form.companyWebsite.label",
    "Company Website"
  );
  const companyWebsiteMessage = s(
    "organization.page.autoconfiguration.createAutoconfiguration.form.companyWebsite.message",
    "Please enter your company website"
  );
  const companyWebsitePlaceholder = s(
    "organization.page.autoconfiguration.createAutoconfiguration.form.companyWebsite.placeholder",
    "microsoft.com"
  );
  const productNameLabel = s(
    "organization.page.autoconfiguration.createAutoconfiguration.form.productName.label",
    "Product Name"
  );
  const productNameMessage = s(
    "organization.page.autoconfiguration.createAutoconfiguration.form.productName.message",
    "Please enter your product name"
  );
  const productNamePlaceholder = s(
    "organization.page.autoconfiguration.createAutoconfiguration.form.productName.placeholder",
    "i.e. Microsoft Excel"
  );

  useLoadingState(
    loadingState,
    () => {
      history.push(
        `/organizations/autoconfigurations/${autoconfiguration._id}`
      );
    },
    () => {
      Notification.error(error);
    }
  );

  const onFinish = (values) => {
    createAutoconfiguration(values);
  };

  return (
    <SplitContainer>
      <div>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          name={"autoConfigurationForm"}
          initialValues={{ model: "gpt-3.5-turbo-0301" }}
        >
          <FormItem
            name="companyName"
            label={companyNameLabel}
            rules={[{ required: true, message: companyNameMessage }]}
          >
            <Input placeholder={companyNamePlaceholder} />
          </FormItem>
          <FormItem
            name="companyWebsite"
            label={companyWebsiteLabel}
            rules={[{ required: true, message: companyWebsiteMessage }]}
          >
            <Input placeholder={companyWebsitePlaceholder} />
          </FormItem>
          <FormItem
            name="productName"
            label={productNameLabel}
            rules={[{ required: true, message: productNameMessage }]}
          >
            <Input placeholder={productNamePlaceholder} />
          </FormItem>
          <FormItem>
            <ButtonGroup mobile={isMobile}>
              <NewButton
                type={"submit"}
                data-cy={"auto-configuration-form-save"}
              >
                {submitButtonText}
              </NewButton>
            </ButtonGroup>
          </FormItem>
        </Form>
      </div>
      <div></div>
    </SplitContainer>
  );
};

const SplitContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  height: 100%;

  & > div {
    flex-grow: 1;
    max-width: calc(50% - 24px);
  }

  & > div:first-of-type {
    padding: 80px;
  }

  & > div:last-of-type {
    background-image: url(${themeProp("assets.discovery_cover")});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100%;
  }
`;

const mapStateToProps = (state) => ({
  loadingState: selectRequestState(
    state,
    actionTypes.CREATE_AUTOCONFIGURATION_REQUEST
  ),
  autoconfiguration: selectRequestData(
    state,
    actionTypes.CREATE_AUTOCONFIGURATION_REQUEST
  )
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createAutoconfiguration
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(CreateAutoconfiguration);
