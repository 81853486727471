import { bindActionCreators, compose } from "redux";
import { cloneDiscovery } from "../../store/actions/discoveries";
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { useString as s } from "../StringProvider";
import Form from "../Form";
import { DiscoveryAlert } from "./NewDiscoveryModal";
import PropTypes from "prop-types";
import {
  selectAddDiscoveryError,
  selectAddDiscoveryResult
} from "../../store/reducers";
import NewButton from "../NewButton";
import { useFeature } from "../FeaturesProvider";
import DiscoveryForm from "../Discovery/DiscoveryForm";
import SourceTypes from "../../utils/source-types";
import Modal from "../Modal";
import Notification from "../Notification";

const CloneDiscoveryModal = ({
  discoveryId,
  accountId,
  accountName,
  accountSource,
  opportunityId,
  opportunityName,
  opportunitySource,
  currency,
  discoveryTypeCode,
  result,
  error,
  onClose,
  cloneDiscovery
}) => {
  const [form] = Form.useForm();
  const okText = s("clone_discovery.popup.button1", "Create discovery");
  const cancelText = s("clone_discovery.popup.button2", "Cancel");
  const errorText = s(
    "clone_discovery.popup.error.text",
    "Failed to clone discovery"
  );
  const successText = s(
    "clone_discovery.popup.success.text",
    "Discovery was cloned"
  );
  const [isDuplicateError, setDuplicateError] = useState(false);
  const externalDiscoveryManagement = useFeature(
    "externalDiscoveryManagement",
    false
  );

  useEffect(() => {
    form.setFieldsValue({
      accountId,
      accountName,
      opportunityId,
      opportunityName,
      currency,
      discoveryTypeCode,
      version: ""
    });
  }, [
    form,
    accountId,
    accountName,
    opportunityId,
    opportunityName,
    currency,
    discoveryTypeCode
  ]);

  useEffect(() => {
    if (result) {
      onClose();
      Notification.success(successText);
    }
  }, [result]);

  useEffect(() => {
    if (error) {
      if (error.status === 409) {
        setDuplicateError(true);
      } else {
        Notification.error(errorText);
      }
    } else {
      setDuplicateError(false);
    }
  }, [error]);

  const onFinish = async (values) => {
    cloneDiscovery({ discoveryId, discoveryData: values });
  };

  return (
    <Modal
      title={s("new_discovery.popup.header", "New Discovery")}
      open={true}
      onCancel={onClose}
      data-cy={"clone-discovery-modal"}
    >
      {isDuplicateError ? <DiscoveryAlert /> : null}
      <DiscoveryForm
        isNameEditable={!externalDiscoveryManagement}
        isOpportunityEditable={!externalDiscoveryManagement}
        isCurrencyEditable={!externalDiscoveryManagement}
        accountSource={accountSource}
        opportunitySource={opportunitySource}
        form={form}
        okText={okText}
        onFinish={onFinish}
      >
        <NewButton type={"secondary"} onClick={onClose}>
          {cancelText}
        </NewButton>
      </DiscoveryForm>
    </Modal>
  );
};

CloneDiscoveryModal.propTypes = {
  discoveryId: PropTypes.string.isRequired,
  accountId: PropTypes.string.isRequired,
  accountName: PropTypes.string.isRequired,
  accountSource: PropTypes.oneOf(SourceTypes.ALL),
  opportunityId: PropTypes.string.isRequired,
  opportunityName: PropTypes.string.isRequired,
  opportunitySource: PropTypes.oneOf(SourceTypes.ALL),
  currency: PropTypes.string.isRequired,
  discoveryTypeCode: PropTypes.string,
  onClose: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  result: selectAddDiscoveryResult(state),
  error: selectAddDiscoveryError(state)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      cloneDiscovery
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  CloneDiscoveryModal
);
