import { useString as s } from "../../../components/StringProvider";
import Text from "../../../components/Text";
import NewButton from "../../../components/NewButton";
import styled from "styled-components";
import PropTypes from "prop-types";
import useLoadingState from "../../../utils/use-loading-state";
import ButtonGroup from "../../../components/ButtonGroup";
import { Modal } from "../../../components";
import Notification from "../../../components/Notification";

const DisconnectModal = ({
  deleteLoadingState,
  onClose,
  header,
  subHeader,
  handleDelete
}) => {
  const ok = s("disconnect.modal.ok.label", "Disconnect");
  const cancel = s("disconnect.modal.cancel.label", "Cancel");
  const disconnectError = s(
    "disconnect.modal.messages.deleteError",
    "Failed to disconnect"
  );

  useLoadingState(
    deleteLoadingState,
    () => {
      onClose();
    },
    () => {
      Notification.error(disconnectError);
    }
  );

  const onDisconnect = async () => {
    handleDelete();
  };

  return (
    <Modal
      title={header}
      open={true}
      onCancel={onClose}
      data-cy={"disconnect-integration-modal"}
    >
      <WarningText>
        <Text>{subHeader}</Text>
      </WarningText>
      <ButtonGroup>
        <NewButton
          type={"primary"}
          onClick={onDisconnect}
          data-cy={"modal-disconnect-button"}
        >
          {ok}
        </NewButton>
        <NewButton
          type={"secondary"}
          onClick={onClose}
          data-cy={"modal-cancel-button"}
        >
          {cancel}
        </NewButton>
      </ButtonGroup>
    </Modal>
  );
};

const WarningText = styled.div`
  margin-bottom: 16px;
`;

DisconnectModal.propTypes = {
  deleteLoadingState: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.string.isRequired,
  handleDelete: PropTypes.func.isRequired
};

export default DisconnectModal;
