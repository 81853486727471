import { selectRequestData, selectRequestState } from "../../../store/reducers";
import actionTypes from "../../../store/actionTypes";
import { compose } from "redux";
import { connect } from "react-redux";
import { useString as s } from "../../../components/StringProvider";
import useLoadingState from "../../../utils/use-loading-state";
import openDownload from "../../../utils/open-download";
import Notification from "../../../components/Notification";

const FileDownloadMonitor = ({
  loadingState,
  download,
  transformLoadingState,
  transformDownload
}) => {
  const failedToDownload = s(
    "organization.page.currentConfiguration.messages.failedToDownload",
    "Failed to download file"
  );

  useLoadingState(
    loadingState,
    () => {
      openDownload(download.url);
    },
    () => {
      Notification.error(failedToDownload);
    }
  );

  useLoadingState(
    transformLoadingState,
    () => {
      openDownload(transformDownload.url);
    },
    () => {
      Notification.error(failedToDownload);
    }
  );

  return null;
};

const mapStateToProps = (state) => ({
  loadingState: selectRequestState(state, actionTypes.DOWNLOAD_FILE_REQUEST),
  download: selectRequestData(state, actionTypes.DOWNLOAD_FILE_REQUEST),
  transformLoadingState: selectRequestState(
    state,
    actionTypes.TRANSFORM_AND_DOWNLOAD_FILE_REQUEST
  ),
  transformDownload: selectRequestData(
    state,
    actionTypes.TRANSFORM_AND_DOWNLOAD_FILE_REQUEST
  )
});

export default compose(connect(mapStateToProps))(FileDownloadMonitor);
