import {
  selectDiscoveryChallenge,
  selectDiscoveryId,
  selectRequestState
} from "../../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { useEffect } from "react";
import { useString as s } from "../../StringProvider";
import Form from "../../Form";
import { saveDiscoveryChallenge } from "../../../store/actions/discoveries";
import DefinitionTypes from "../../../utils/definition-types";
import { stopEditingChallenge } from "../../../store/actions/edit-challenges";
import ExistingChallengeForm from "./ExistingChallengeForm";
import NewChallengeForm from "./NewChallengeForm";
import PropType from "prop-types";
import ChallengeModal from "./ChallengeModal";
import UNCATEGORIZED_CATEGORY_CODE from "../../../utils/uncategorized-category-code";
import actionType from "../../../store/actionTypes";
import useLoadingState from "../../../utils/use-loading-state";
import { setScrollToChallengeCode } from "../../../store/actions/challenge-library";
import Notification from "../../Notification";

const EditChallengeModal = ({
  discoveryId,
  challengeCode,
  loadingState,
  discoveryChallenge,
  saveDiscoveryChallenge,
  stopEditingChallenge,
  setScrollToChallengeCode
}) => {
  const errorText = s(
    "editChallenge.popup.error.text",
    "Failed to save challenge"
  );
  const successText = s(
    "editChallenge.popup.success.text",
    "Card saved! Changes will be applied to the current discovery only."
  );
  const [form] = Form.useForm();

  useEffect(() => {
    setScrollToChallengeCode({ challengeCode });
  }, [challengeCode]);

  useLoadingState(
    loadingState,
    () => {
      Notification.success(successText);
      stopEditingChallenge();
    },
    () => {
      Notification.error(errorText);
    }
  );

  useEffect(() => {
    if (discoveryChallenge) {
      const categoryCode = discoveryChallenge.definition.categoryCode;
      const data = {
        description: discoveryChallenge.definition.description,
        categoryCode:
          categoryCode !== UNCATEGORIZED_CATEGORY_CODE
            ? categoryCode
            : undefined,
        kpiMappings: discoveryChallenge.definition.kpiMappings,
        detailText: discoveryChallenge.definition.detailText
      };

      form.resetFields();
      form.setFieldsValue(data);
    }
  }, [discoveryChallenge]);

  const onFinish = ({ description, categoryCode, kpiMappings, detailText }) => {
    const changes = {
      definition: {
        description,
        detailText
      }
    };

    if (discoveryChallenge.definition.status === DefinitionTypes.NEW) {
      changes.definition.categoryCode = !categoryCode ? null : categoryCode;
    }

    if (discoveryChallenge.definition.status === DefinitionTypes.NEW) {
      changes.definition.kpiMappings = kpiMappings ? kpiMappings : [];
    }

    saveDiscoveryChallenge({
      discoveryId,
      challengeCode,
      changes
    });
  };

  return (
    <ChallengeModal open={true} data-cy={"edit-challenge-modal"}>
      {discoveryChallenge.definition.status === DefinitionTypes.NEW ? (
        <NewChallengeForm
          form={form}
          discoveryChallenge={discoveryChallenge}
          onFinish={onFinish}
          onCancel={stopEditingChallenge}
          isEdit={true}
        />
      ) : (
        <ExistingChallengeForm
          form={form}
          onFinish={onFinish}
          onCancel={stopEditingChallenge}
          discoveryChallenge={discoveryChallenge}
        />
      )}
    </ChallengeModal>
  );
};

EditChallengeModal.propTypes = {
  challengeCode: PropType.string.isRequired
};

const mapStateToProps = (state, props) => {
  return {
    discoveryId: selectDiscoveryId(state),
    loadingState: selectRequestState(
      state,
      actionType.DISCOVERY_SAVE_CHALLENGE_REQUEST
    ),
    discoveryChallenge: selectDiscoveryChallenge(state, props.challengeCode)
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveDiscoveryChallenge,
      stopEditingChallenge,
      setScrollToChallengeCode
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  EditChallengeModal
);
