import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { SplitPageLayout } from "../../components/Layout";
import Page from "../../components/Page";
import { useTheme } from "../../components/ThemeProvider";
import VerifyAccountForm from "./VerifyAccountForm";
import Form from "../../components/Form";
import {
  login,
  resetPassword,
  forgotPassword
} from "../../store/actions/authentication";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  isAuthenticated,
  selectRequestState,
  selectRequestError
} from "../../store/reducers";
import actionTypes from "../../store/actionTypes";
import useLoadingState from "../../utils/use-loading-state";
import { useString as s } from "../../components/StringProvider";
import Notification from "../../components/Notification";

const VerifyAccount = ({
  history,
  invitation,
  email,
  resetPassword,
  login,
  resetPasswordLoadingState,
  isAuthenticated,
  forgotPassword,
  resetPasswordError,
  resendLoadingState
}) => {
  const resendSuccessful = s(
    "verify.account.page.messages.resendCode",
    "Code resent."
  );
  const resendFailed = s(
    "verify.account.page.messages.resendFailed",
    "Failed to resend code."
  );

  const [form] = Form.useForm();
  const [password, setPassword] = useState();

  const onFinish = async ({ code, password }) => {
    setPassword(password);
    resetPassword({
      email,
      confirmationCode: code,
      password
    });
  };

  const onResendCode = () => {
    forgotPassword({ email, verifyAccount: true });
  };

  useEffect(() => {
    if (isAuthenticated) {
      if (invitation) {
        history.push({
          pathname: `/discoveries/${invitation.discoveryId}/select-challenges`
        });
      } else {
        history.push({
          pathname: `/`
        });
      }
    }
  }, [isAuthenticated]);

  useLoadingState(
    resetPasswordLoadingState,
    () => {
      login({ email, password });
    },
    () => {}
  );

  useLoadingState(
    resendLoadingState,
    () => {
      Notification.success(resendSuccessful);
    },
    () => {
      Notification.error(resendFailed);
    }
  );

  return (
    <Page>
      <SplitPageLayout image={useTheme("assets.reset_cover")}>
        <VerifyAccountForm
          invitation={invitation}
          email={email}
          onFinish={onFinish}
          onResendCode={onResendCode}
          form={form}
          error={resetPasswordError?.data?.message}
        />
      </SplitPageLayout>
    </Page>
  );
};

VerifyAccount.propTypes = {
  invitation: PropTypes.object,
  email: PropTypes.string
};

const mapStateToProps = (state) => {
  return {
    resetPasswordLoadingState: selectRequestState(
      state,
      actionTypes.RESET_PASSWORD_REQUEST
    ),
    resetPasswordError: selectRequestError(
      state,
      actionTypes.RESET_PASSWORD_REQUEST
    ),
    isAuthenticated: isAuthenticated(state),
    resendLoadingState: selectRequestState(
      state,
      actionTypes.RESEND_CONFIRMATION_REQUEST
    )
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetPassword,
      login,
      forgotPassword
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(VerifyAccount);
