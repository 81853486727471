import { selectFeatures, selectRequestState } from "../../../../store/reducers";
import types from "../../../../store/actionTypes";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import Form from "../../../../components/Form";
import ManageFeaturesForm from "./ManageFeaturesForm";
import { useEffect } from "react";
import useLoadingState from "../../../../utils/use-loading-state";
import { useString as s } from "../../../../components/StringProvider";
import { updateFeatures } from "../../../../store/actions/features";
import styled from "styled-components";
import Features from "../../../../utils/constants/Features";
import Notification from "../../../../components/Notification";

const ManageFeatures = ({ features, loadingState, updateFeatures }) => {
  const [form] = Form.useForm();
  const successText = s("manageFeatures.messages.success", "Updated features.");
  const failedText = s(
    "manageFeatures.messages.failure",
    "Failed to update features."
  );

  useLoadingState(
    loadingState,
    () => {
      Notification.success(successText);
    },
    () => {
      Notification.error(failedText);
    }
  );

  useEffect(() => {
    const featuresList = Object.entries(features).reduce(
      (list, [key, value]) => {
        if (value) {
          return [...list, key];
        }

        return list;
      },
      []
    );

    form.setFieldsValue({
      features: featuresList
    });
  }, [features]);

  const onFinish = ({ features: newFeatures }) => {
    const changes = {};

    for (const feature of Object.values(Features)) {
      if (features[feature] === undefined) {
        // If the original value is undefined, set true if the option is checked
        changes[feature] = newFeatures.includes(feature) ? true : undefined;
      } else if (features[feature] !== newFeatures.includes(feature)) {
        // If the original value is defined, only set it if the value has changed
        // Incidentally, this will prevent the setting of fields that managed
        // by the system, like discoveryTypesEnabled
        changes[feature] = newFeatures.includes(feature);
      }
    }

    updateFeatures({ changes });
  };

  return (
    <Container>
      <ManageFeaturesForm form={form} onFinish={onFinish} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const mapStateToProps = (state) => ({
  features: selectFeatures(state),
  loadingState: selectRequestState(state, types.UPDATE_FEATURES_REQUEST)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ updateFeatures }, dispatch);

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ManageFeatures
);
