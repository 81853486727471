import {
  selectDiscoveryCurrency,
  selectDiscoveryId,
  selectDiscoveryKPI,
  selectEditKPICode,
  selectRequestState,
  selectValueNote,
  selectValueNoteLoadingState
} from "../../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { stopEditingKPI } from "../../../store/actions/edit-kpis";
import React, { useEffect, useState } from "react";
import LoadingState from "../../../utils/loading-state";
import { useString as s } from "../../StringProvider";
import Form from "../../Form";
import { saveDiscoveryKPI } from "../../../store/actions/discoveries";
import { deleteNullOrUndefinedField } from "../../../utils/formatting";
import EditKPIValueForm from "./EditKPIValueForm";
import {
  getDiscoveryCommentCount,
  getValueNote
} from "../../../store/actions/comments";
import actionTypes from "../../../store/actionTypes";
import useLoadingState from "../../../utils/use-loading-state";
import Modal from "../../Modal";
import { isQuantifiedKPI } from "../../../utils/filters/kpi-filters";
import Notification from "../../Notification";

const isNullOrUndefined = (v) => v === null || v === undefined;

const EditKPIValueModal = ({
  discoveryId,
  kpiCode,
  loadingState,
  valueNoteLoadingState,
  currency,
  discoveryKPI,
  comment,
  saveDiscoveryKPI,
  stopEditingKPI,
  getDiscoveryCommentCount,
  getValueNote
}) => {
  const errorText = s(
    "editKPIValue.popup.error.text",
    "Failed to save outcome"
  );
  const valueNoteErrorText = s(
    "editKPIValue.popup.valueNoteError.text",
    "Failed to load value note"
  );
  const successText = s(
    "editKPIValue.popup.success.text",
    "Card saved! Changes will be applied to the current discovery only."
  );
  const titleText = s("editKPIValue.popup.header", "New Customer KPI Value");
  const [form] = Form.useForm();
  const [valueNoteProgressing, setValueNoteProgressing] = useState(false);

  useLoadingState(
    loadingState,
    () => {
      if (kpiCode) {
        Notification.success(successText);
        stopEditingKPI();
        getDiscoveryCommentCount({ discoveryId });
      }
    },
    () => {
      Notification.error(errorText);
    }
  );

  useEffect(() => {
    if (kpiCode) {
      getValueNote({ discoveryId, kpiCode });
    }
  }, [discoveryId, kpiCode, getValueNote]);

  useEffect(() => {
    if (valueNoteLoadingState === LoadingState.IN_PROGRESS) {
      setValueNoteProgressing(true);
    } else if (kpiCode && valueNoteLoadingState === LoadingState.FAILED) {
      Notification.error(valueNoteErrorText);
      stopEditingKPI();
    }
  }, [valueNoteLoadingState, kpiCode, setValueNoteProgressing, stopEditingKPI]);

  useEffect(() => {
    if (discoveryKPI) {
      const data = {
        lowImpactPercentage: discoveryKPI.lowImpactPercentage,
        highImpactPercentage: discoveryKPI.highImpactPercentage,
        lowImpactValue: discoveryKPI.lowImpactValue,
        potentialImpactValue: discoveryKPI.potentialImpactValue
      };

      deleteNullOrUndefinedField(data, "lowImpactPercentage");
      deleteNullOrUndefinedField(data, "highImpactPercentage");
      deleteNullOrUndefinedField(data, "lowImpactValue");
      deleteNullOrUndefinedField(data, "potentialImpactValue");

      if (comment) {
        data.commentText = comment.text;
      }

      form.resetFields();
      form.setFieldsValue(data);
    }
  }, [discoveryKPI, comment]);

  const onFinish = ({
    lowImpactPercentage,
    highImpactPercentage,
    lowImpactValue,
    potentialImpactValue,
    commentText
  }) => {
    const changes = {
      commentText
    };

    if (
      (isNullOrUndefined(lowImpactPercentage) &&
        !isNullOrUndefined(discoveryKPI.lowImpactPercentage)) ||
      lowImpactPercentage === 0
    ) {
      changes.lowImpactPercentage = null;
    } else if (!isNullOrUndefined(lowImpactPercentage)) {
      changes.lowImpactPercentage = lowImpactPercentage;
    }

    if (
      (isNullOrUndefined(highImpactPercentage) &&
        !isNullOrUndefined(discoveryKPI.highImpactPercentage)) ||
      highImpactPercentage === 0
    ) {
      changes.highImpactPercentage = null;
    } else if (!isNullOrUndefined(highImpactPercentage)) {
      changes.highImpactPercentage = highImpactPercentage;
    }

    if (
      (isNullOrUndefined(lowImpactValue) &&
        !isNullOrUndefined(discoveryKPI.lowImpactValue)) ||
      lowImpactValue === 0
    ) {
      changes.lowImpactValue = null;
    } else if (!isNullOrUndefined(lowImpactValue)) {
      changes.lowImpactValue = lowImpactValue;
    }

    if (
      (isNullOrUndefined(potentialImpactValue) &&
        !isNullOrUndefined(discoveryKPI.potentialImpactValue)) ||
      potentialImpactValue === 0
    ) {
      changes.potentialImpactValue = null;
    } else if (!isNullOrUndefined(potentialImpactValue)) {
      changes.potentialImpactValue = potentialImpactValue;
    }

    saveDiscoveryKPI({
      discoveryId,
      kpiCode,
      changes
    });
  };

  return kpiCode &&
    !isQuantifiedKPI({ discoveryKPI }) &&
    valueNoteProgressing &&
    valueNoteLoadingState === LoadingState.COMPLETED ? (
    <Modal
      open={true}
      onCancel={stopEditingKPI}
      data-cy={"edit-kpi-value-modal"}
      title={titleText}
      titleSize={"h3"}
    >
      <EditKPIValueForm
        currency={currency}
        form={form}
        hasComment={comment !== null}
        onCancel={stopEditingKPI}
        onFinish={onFinish}
        discoveryKPI={discoveryKPI}
      />
    </Modal>
  ) : null;
};

const mapStateToProps = (state) => {
  return {
    discoveryId: selectDiscoveryId(state),
    kpiCode: selectEditKPICode(state),
    loadingState: selectRequestState(
      state,
      actionTypes.DISCOVERY_SAVE_KPI_REQUEST
    ),
    currency: selectDiscoveryCurrency(state),
    discoveryKPI: selectDiscoveryKPI(state, selectEditKPICode(state)),
    comment: selectValueNote(state),
    valueNoteLoadingState: selectValueNoteLoadingState(state)
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveDiscoveryKPI,
      stopEditingKPI,
      getDiscoveryCommentCount,
      getValueNote
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(EditKPIValueModal);
