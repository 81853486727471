import { bindActionCreators, compose } from "redux";
import { reactivateUser } from "../../../../store/actions/users";
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { useString as s } from "../../../../components/StringProvider";
import PropTypes from "prop-types";
import {
  selectAccessManagementUserById,
  selectRequestError,
  selectRequestState
} from "../../../../store/reducers";
import NewButton from "../../../../components/NewButton";
import actionTypes from "../../../../store/actionTypes";
import useLoadingState from "../../../../utils/use-loading-state";
import UserForm from "./UserForm";
import Form from "../../../../components/Form";
import { Modal } from "../../../../components";
import Notification from "../../../../components/Notification";

const ReactivateUserModal = ({
  userId,
  loadingState,
  error,
  onClose,
  reactivateUser,
  user
}) => {
  const [form] = Form.useForm();
  const cancelText = s(
    "accessManagement.page.users.reactivate.cancel",
    "Don't reactivate"
  );
  const errorText = s(
    "accessManagement.page.users.reactivate.error",
    "Failed to reactivate User"
  );
  const successText = s(
    "accessManagement.page.users.reactivate.success",
    "User was successfully reactivated"
  );
  const reactiveButtonText = {
    default: s(
      "accessManagement.page.users.reactivate.submit",
      "Yes, reactivate"
    ),
    loading: s(
      "accessManagement.page.users.reactivate.loading",
      "Reactivating..."
    )
  };

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      email: user.email,
      roleIds: user.roles.map((role) => role._id)
    });
  }, []);

  useLoadingState(
    loadingState,
    () => {
      onClose();
      Notification.success(successText);
      setIsLoading(false);
    },
    () => {
      setIsLoading(false);

      if (error) {
        Notification.error(errorText);
      }
    },
    () => {
      setIsLoading(true);
    }
  );

  const onFinish = ({ roleIds }) => {
    reactivateUser({ userId, roleIds });
  };

  return (
    <Modal
      title={s(
        "accessManagement.popup.reactivateUser.header",
        "Reactivate User"
      )}
      open={true}
      onCancel={onClose}
    >
      <UserForm
        form={form}
        onFinish={onFinish}
        submitButtonText={reactiveButtonText}
        emailDisabled={true}
        isLoading={isLoading}
      >
        <NewButton
          type={"secondary"}
          onClick={onClose}
          data-cy={"dont-reactivate-user"}
        >
          {cancelText}
        </NewButton>
      </UserForm>
    </Modal>
  );
};

ReactivateUserModal.propTypes = {
  userId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

const mapStateToProps = (state, props) => ({
  loadingState: selectRequestState(state, actionTypes.USERS_REACTIVATE_REQUEST),
  error: selectRequestError(state, actionTypes.USERS_REACTIVATE_REQUEST),
  user: selectAccessManagementUserById(state, props.userId)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      reactivateUser
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ReactivateUserModal
);
