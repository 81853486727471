import React, { useEffect, useState } from "react";
import Select from "../../../../components/Select";
import { useString as s } from "../../../../components/StringProvider";
import {
  selectRequestData,
  selectRequestState
} from "../../../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { getRoles } from "../../../../store/actions/roles";
import { connect } from "react-redux";
import useLoadingState from "../../../../utils/use-loading-state";
import LoadingState from "../../../../utils/loading-state";
import actionTypes from "../../../../store/actionTypes";
import Notification from "../../../../components/Notification";

const SelectRoles = ({
  getRoles,
  loadingState,
  roles,
  pagination,
  value,
  ...props
}) => {
  const [allRoles, setAllRoles] = useState([]);
  const [options, setOptions] = useState([]);
  const [optionsLoadingState, setOptionsLoadingState] = useState(
    LoadingState.UNINITIALIZED
  );
  const rolesPlaceholder = s(
    "accessManagement.page.userForm.roles.placeholder",
    "Please select"
  );
  const failedText = s(
    "accessManagement.page.userForm.roles.failed",
    "Failed to load roles"
  );

  useEffect(() => {
    getRoles({ sort: "name", order: "asc" });
  }, []);

  useLoadingState(
    loadingState,
    () => {
      setAllRoles((all) => [...all, ...roles]);

      if (!roles.length) {
        setOptions(
          allRoles.map((role) => ({
            disabled: role.blessed,
            value: role._id,
            label: role.name
          }))
        );
        setOptionsLoadingState(LoadingState.COMPLETED);
      } else {
        getRoles({
          start: pagination.start + pagination.count,
          sort: "name",
          order: "asc"
        });
      }
    },
    () => {
      setOptionsLoadingState(LoadingState.FAILED);
      Notification.error(failedText);
    }
  );

  return (
    <Select
      data-cy={"roles-select"}
      mode="multiple"
      placeholder={rolesPlaceholder}
      options={options}
      optionFilterProp={"label"}
      value={optionsLoadingState === LoadingState.COMPLETED ? value : []}
      {...props}
    />
  );
};

const mapStateToProps = (state) => {
  const rolesData = selectRequestData(state, actionTypes.GET_ROLES_REQUEST);

  return {
    roles: rolesData ? rolesData.items : [],
    pagination: rolesData ? rolesData.pagination : null,
    loadingState: selectRequestState(state, actionTypes.GET_ROLES_REQUEST)
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getRoles
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SelectRoles
);
