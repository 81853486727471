import { useEffect, useState } from "react";
import { useString as s } from "../../../../components/StringProvider";
import ConnectionLayout from "../../../../components/Layout/ConnectionLayout";
import IntegrationConnectionForm from "./IntegrationConnectionForm";
import DisconnectModal from "../DisconnectModal";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import {
  selectRequestData,
  selectRequestState
} from "../../../../store/reducers";
import {
  getIntegration,
  deleteIntegration,
  setIntegration
} from "../../../../store/actions/integrations";
import types from "../../../../store/actionTypes";
import IntegrationNames from "../../../../utils/constants/integration-names";
import useLoadingState from "../../../../utils/use-loading-state";
import { useLocation, useParams } from "react-router-dom";
import { useFeature } from "../../../../components/FeaturesProvider";
import handleOAuthRedirect from "./handle-oauth-redirect";
import { Alert, Icon } from "../../../../components";
import SalesforceInstallation from "../SalesforceInstallation";
import _ from "lodash";
import SalesforceSSOInstallation from "./SalesforceSSOInstallation";
import Notification from "../../../../components/Notification";

const removeTrailingSlash = (url) => url?.replace(/\/$/, "");

const IntegrationConnection = ({
  setIntegrationLoadingState,
  deleteIntegrationLoadingState,
  setIntegration,
  integrationData,
  getIntegration,
  deleteIntegration,
  setIntegrationData
}) => {
  const { name } = useParams();
  const location = useLocation();
  const { search } = location;
  const params = new URLSearchParams(search);
  const connectionURL = removeTrailingSlash(params.get("connectionURL"));
  const integrationError = params.get("integrationError");

  const [editing, setEditing] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const salesforceIntegrationV2 = useFeature("salesforceIntegrationV2", false);
  const salesforceSandbox = useFeature("salesforceSandbox", false);
  const [formFields, setFormFields] = useState([
    "baseUrl",
    "clientId",
    "clientSecret"
  ]);

  const setIntegrationError = s(
    "generalAdmin.page.integrationConnection.messages.setIntegrationError",
    "Failed to update connection information"
  );
  const errorTitle = s(
    "generalAdmin.page.integrationConnection.messages.integrationError",
    "Integration Error"
  );

  const integrationNames = {
    hubspot: s("integrationNames.hubspot", "HubSpot"),
    salesforce: s("integrationNames.salesforce", "Salesforce")
  };
  const integrationName = integrationNames[name];
  const integrationErrorMessages = {
    "error.retrieveIntegrationToken.generalError": s(
      "error.retrieveIntegrationToken.generalError",
      "Unfortunately, we weren't able to connect to {integrationName}. Please check your settings and try again.",
      { integrationName }
    ),
    "error.retrieveIntegrationToken.pingFailed": s(
      "error.retrieveIntegrationToken.pingFailed",
      "Unfortunately, we weren't able to connect to {integrationName}. Please check your settings and try again.",
      { integrationName }
    )
  };
  const helperText = s(
    "generalAdmin.page.integrationConnection.header",
    "Enter the connection details you generated in {integrationName} to activate the connection between Cuvama and {integrationName}.",
    { integrationName }
  );
  const disconnectHeader = s(
    "disconnectIntegration.popup.header",
    "Disconnect {integrationName}",
    { integrationName }
  );
  const disconnectSubheader = s(
    "disconnectIntegration.popup.subheader",
    "Do you want to disconnect from {integrationName} and delete your settings?",
    { integrationName }
  );

  const salesforceConnectionTitle = s(
    "generalAdmin.page.salesforceConnection.title",
    "Salesforce Connection"
  );

  const title =
    name === IntegrationNames.SALESFORCE && salesforceIntegrationV2
      ? salesforceConnectionTitle
      : undefined;

  useEffect(() => {
    if (name === IntegrationNames.HUBSPOT) {
      setFormFields([]);
    } else if (
      name === IntegrationNames.SALESFORCE &&
      salesforceIntegrationV2
    ) {
      setFormFields(["baseUrl"]);
    }
  }, [salesforceIntegrationV2, name]);

  useEffect(() => {
    getIntegration({ name });
  }, [name]);

  useEffect(() => {
    if (
      !_.isEqual({}, integrationData) &&
      connectionURL &&
      name === IntegrationNames.SALESFORCE &&
      salesforceIntegrationV2
    ) {
      deleteIntegration({ name });
    }

    if (
      connectionURL &&
      name === IntegrationNames.SALESFORCE &&
      salesforceIntegrationV2
    ) {
      setEditing(true);
    } else {
      setEditing(!integrationData);
    }
  }, [integrationData]);

  useLoadingState(
    setIntegrationLoadingState,
    () => {
      handleOAuthRedirect({
        salesforceIntegrationV2,
        salesforceSandbox,
        name,
        ...setIntegrationData
      });
    },
    () => {
      Notification.error(setIntegrationError);
    }
  );

  const onConnect = ({ baseUrl, clientId, clientSecret }) => {
    setIntegration({
      name,
      baseUrl,
      clientId,
      clientSecret
    });
  };

  const onUpdate = ({ baseUrl, clientId, clientSecret }) => {
    setIntegration({
      name,
      baseUrl,
      clientId,
      clientSecret
    });
  };

  const onEdit = () => {
    setEditing(true);
  };

  const onCancelEdit = () => {
    setEditing(false);
  };

  const onDisconnect = () => {
    setDeleteModalVisible(true);
  };

  const onDelete = () => {
    deleteIntegration({ name });
  };

  const getIntegrationData = () => {
    if (connectionURL) {
      return { ...integrationData, baseUrl: connectionURL };
    }

    return integrationData;
  };

  return (
    <ConnectionLayout
      title={title}
      helperText={helperText}
      isConnected={!!integrationData?.connected}
      key={name}
    >
      <IntegrationConnectionForm
        formFields={formFields}
        integrationName={integrationNames[name]}
        integrationData={getIntegrationData()}
        onConnect={onConnect}
        onCancel={onCancelEdit}
        onDisconnect={onDisconnect}
        onEdit={onEdit}
        onUpdate={onUpdate}
        readOnly={!editing}
        isConnected={!!integrationData?.connected}
      />
      {deleteModalVisible && (
        <DisconnectModal
          onClose={() => setDeleteModalVisible(false)}
          deleteLoadingState={deleteIntegrationLoadingState}
          header={disconnectHeader}
          subHeader={disconnectSubheader}
          handleDelete={onDelete}
        />
      )}
      {integrationError && !editing && (
        <Alert
          data-cy={"integration-error"}
          message={errorTitle}
          description={
            integrationErrorMessages[integrationError] ||
            integrationErrorMessages[
              "error.retrieveIntegrationToken.generalError"
            ]
          }
          type={"error"}
          icon={<Icon size={"larger"} name={"alert"} />}
          showIcon
        />
      )}

      {name === IntegrationNames.SALESFORCE && salesforceIntegrationV2 && (
        <SalesforceInstallation />
      )}

      {name === IntegrationNames.SALESFORCE && salesforceIntegrationV2 && (
        <SalesforceSSOInstallation />
      )}
    </ConnectionLayout>
  );
};

const mapStateToProps = (state) => ({
  deleteIntegrationLoadingState: selectRequestState(
    state,
    types.DELETE_INTEGRATION_REQUEST
  ),
  setIntegrationLoadingState: selectRequestState(
    state,
    types.SET_INTEGRATION_REQUEST
  ),
  integrationData:
    selectRequestData(state, types.GET_INTEGRATION_REQUEST) || {},
  setIntegrationData: selectRequestData(state, types.SET_INTEGRATION_REQUEST)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { setIntegration, getIntegration, deleteIntegration },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  IntegrationConnection
);
