import PropTypes from "prop-types";
import { Space } from "antd";
import styled from "styled-components";
import { useString as s } from "../../StringProvider";
import Text from "../../Text";
import { selectDiscoveryId, selectRequestState } from "../../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { registerDiscoveryInterest } from "../../../store/actions/discoveries";
import Heading from "../../Heading";
import Icon from "../../Icon";
import { DesktopOrTablet, Mobile } from "../../Responsive";
import RegisterInterestForm from "./RegisterInterestForm";
import useLoadingState from "../../../utils/use-loading-state";
import actionTypes from "../../../store/actionTypes";
import Modal from "../../Modal";
import Notification from "../../Notification";

const RegisterInterestModal = ({
  discoveryId,
  loadingState,
  registerDiscoveryInterest,
  onClose
}) => {
  const title = s("register_interest.popup.title", "One more step");
  const header = s(
    "register_interest.popup.header",
    "To get the results of the discovery, please tell us about yourself"
  );
  const errorMessageText = s(
    "register_interest.popup.messages.error",
    "Failed to submit form"
  );

  useLoadingState(
    loadingState,
    () => {
      onClose();
    },
    () => {
      Notification.error(errorMessageText);
    }
  );

  const onFinish = (values) => {
    registerDiscoveryInterest({
      ...values,
      discoveryId,
      contactMe: !!values.contactMe
    });
  };

  return (
    <Modal
      open={true}
      onCancel={onClose}
      width={800}
      data-cy={"register-interest-modal"}
    >
      <Mobile>
        <MobileContainer>
          <Space direction={"vertical"} size={9} align={"center"}>
            <Icon name={"paperPlane"} size={"large"} colour={"primary"} />
            <Heading level={"h4"}>{title}</Heading>
            <Text variant={"bodyMobile"}>{header}</Text>
          </Space>
          <RegisterInterestForm onFinish={onFinish} />
        </MobileContainer>
      </Mobile>
      <DesktopOrTablet>
        <Container>
          <Left>
            <Icon name={"paperPlane"} size={"xlarge"} colour={"primary"} />
            <Heading level={"h2"}>{title}</Heading>
            <Text>{header}</Text>
          </Left>
          <Right>
            <RegisterInterestForm onFinish={onFinish} />
          </Right>
        </Container>
      </DesktopOrTablet>
    </Modal>
  );
};

const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;

  & h4 {
    margin-bottom: 0;
  }

  & > *:first-child {
    margin-top: 20px;
    margin-bottom: 26px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 40px;
`;

const Left = styled.div`
  margin-right: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-grow: 1;

  h2 {
    margin-top: 24px;
    text-align: center;
  }
`;

const Right = styled.div`
  flex-grow: 1;
`;

const mapStateToProps = (state) => ({
  loadingState: selectRequestState(
    state,
    actionTypes.DISCOVERY_REGISTER_INTEREST_REQUEST
  ),
  discoveryId: selectDiscoveryId(state)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      registerDiscoveryInterest
    },
    dispatch
  );

RegisterInterestModal.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  RegisterInterestModal
);
