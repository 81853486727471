import ValueMapList, { bottomAreaItem } from "./ValueMapList";
import { useEffect, useRef, useState } from "react";
import {
  selectDiscoveryAllKPIs,
  selectDiscoveryId,
  selectDiscoverySetting,
  selectRequestState,
  selectRequestData
} from "../../store/reducers";
import { bindActionCreators, compose } from "redux";
import {
  deleteDiscoveryKPI,
  removeDiscoveryKPIFromLibrary,
  reorderDiscoveryKPIs,
  saveDiscoveryKPI,
  updateDiscoverySettings,
  getAutocompleteDiscoveryKPILibrary,
  addDiscoveryKPIFromLibrary
} from "../../store/actions/discoveries";
import { connect } from "react-redux";
import { useString as s } from "../../components/StringProvider";
import actionTypes from "../../store/actionTypes";
import useLoadingState from "../../utils/use-loading-state";
import ItemTypes from "../../components/ItemTypes";
import { reorderItems } from "./value-map-utils";
import { createDiscoveryKPI } from "../../store/actions/create-kpis";
import { sortDiscoveryKPIsByOrder } from "../../utils/sorting";
import { isCustomKPI } from "../../utils/filters/kpi-filters";
import {
  sendUserPilotEvent,
  UserPilotEventNames
} from "../../utils/user-pilot-util";
import Notification from "../../components/Notification";

const ValueMapOutcomeList = ({
  discoveryId,
  discoveryKPIs,
  kpisEditMode,
  kpisShowSelectedOnly,
  saveDiscoveryKPI,
  updateDiscoverySettings,
  saveLoadingState,
  reorderDiscoveryKPIs,
  createDiscoveryKPI,
  createLoadingState,
  deleteDiscoveryKPI,
  removeDiscoveryKPIFromLibrary,
  deleteLoadingState,
  removeLoadingState,
  reorderLoadingState,
  restoreLoadingState,
  getAutocompleteDiscoveryKPILibrary,
  kpiLibrary,
  addDiscoveryKPIFromLibrary
}) => {
  const [items, setItems] = useState([]);
  const oldItems = useRef([]);
  const [minOrder, setMinOrder] = useState(0);

  const showSelectedOnly = useRef(kpisShowSelectedOnly);
  const outcomesTitleText = s("valueMap.page.outcomes.title", "Outcomes");
  const outcomesCTA = s(
    "valueMap.page.outcomes.cta",
    "What measurable outcomes will indicate success?"
  );
  const emptyText = s(
    "valueMap.page.outcomes.empty",
    "Click on the “+” above to add an outcome"
  );
  const unselectedHiddenText = s(
    "valueMap.page.outcomes.unselectedHidden",
    "Hover on the outcomes header and click on the downward arrow to show all outcomes"
  );
  const failedToCreate = s(
    "createKPI.popup.error.text",
    "Failed to create outcome"
  );
  const failedToSave = s("editKPI.popup.error.text", "Failed to save outcome");
  const failedToRemove = s(
    "discovery.kpi.card.removeFromList.failed",
    "Failed to remove outcome from the discovery!"
  );
  const failedToDelete = s(
    "discovery.kpi.card.delete.failed",
    "Failed to delete outcome from the discovery!"
  );
  const failedToReorder = s(
    "valueMap.page.outcomes.reorder.failed",
    "Failed to reorder outcomes"
  );
  const failedToRestore = s(
    "valueMap.page.outcomes.restore.failed",
    "Failed to restore outcome"
  );

  const onReorder = ({ code, destinationCode, direction }) => {
    const bottomItemCode = oldItems.current[oldItems.current.length - 1]?.code;
    const newItems = reorderItems({
      code,
      destinationCode:
        destinationCode === bottomAreaItem.code
          ? bottomItemCode
          : destinationCode,
      direction,
      items: oldItems.current,
      showSelectedOnly: showSelectedOnly.current
    });

    setItems(newItems);

    reorderDiscoveryKPIs({
      discoveryId,
      codes: newItems.map((i) => i.code)
    });
  };

  useEffect(() => {
    showSelectedOnly.current = kpisShowSelectedOnly;
  }, [kpisShowSelectedOnly]);

  const onToggleEdit = () => {
    updateDiscoverySettings({
      discoveryId,
      changes: {
        canvasKPIsEditMode: !kpisEditMode
      }
    });
  };

  const onToggleShowSelected = () => {
    showSelectedOnly.current = !showSelectedOnly.current;
    updateDiscoverySettings({
      discoveryId,
      changes: {
        canvasKPIsShowSelectedOnly: showSelectedOnly.current
      }
    });
  };

  const onSaveOutcome = ({ item, data }) => {
    const changes = {};

    if ("selected" in data) {
      changes.selected = data.selected;
    }

    if ("text" in data) {
      changes.definition = { name: data.text };
    }

    saveDiscoveryKPI({ discoveryId, kpiCode: item.code, changes });

    const userPilotEventName =
      changes.hasOwnProperty("selected") && changes.selected
        ? UserPilotEventNames.SELECT_OUTCOME
        : UserPilotEventNames.DESELECT_OUTCOME;

    sendUserPilotEvent({
      eventName: userPilotEventName,
      data: { discoveryId, code: item.code }
    });
  };

  const filterSelected = ({ items }) => {
    if (!showSelectedOnly.current) {
      return items.concat(bottomAreaItem);
    }

    return items.filter((item) => item.selected).concat(bottomAreaItem);
  };

  const onCreateKPI = ({ text, fromLibrary = false, code }) => {
    if (fromLibrary) {
      addDiscoveryKPIFromLibrary({
        discoveryId,
        kpiCode: code,
        order: minOrder - 1
      });
    } else {
      createDiscoveryKPI({
        discoveryId,
        data: { definition: { order: minOrder - 1, name: text } }
      });
    }
  };

  const onDeleteKPI = ({ item }) => {
    if (item.custom) {
      deleteDiscoveryKPI({ discoveryId, kpiCode: item.code });
    } else {
      removeDiscoveryKPIFromLibrary({
        discoveryId,
        kpiCode: item.code
      });
    }

    sendUserPilotEvent({
      eventName: UserPilotEventNames.REMOVE_OUTCOME,
      data: { discoveryId, code: item.code }
    });
  };

  useLoadingState(
    saveLoadingState,
    () => {},
    () => {
      Notification.error(failedToSave);
    }
  );

  useLoadingState(
    createLoadingState,
    () => {},
    () => {
      Notification.error(failedToCreate);
    }
  );

  useLoadingState(
    removeLoadingState,
    () => {},
    () => {
      Notification.error(failedToRemove);
    }
  );

  useLoadingState(
    deleteLoadingState,
    () => {},
    () => {
      Notification.error(failedToDelete);
    }
  );

  useLoadingState(
    reorderLoadingState,
    () => {},
    () => {
      Notification.error(failedToReorder);
    }
  );

  useLoadingState(
    restoreLoadingState,
    () => {},
    () => {
      Notification.error(failedToRestore);
    }
  );

  useEffect(() => {
    if (discoveryKPIs) {
      const kpis = discoveryKPIs.filter((k) => k.relevant && k.inScope);

      kpis.sort(sortDiscoveryKPIsByOrder);

      setMinOrder(kpis?.length ? kpis[0].order : 0);

      setItems(
        kpis.map((k) => ({
          code: k.kpiCode,
          text: k.definition.name,
          selected: k.selected,
          custom: isCustomKPI({ discoveryKPI: k })
        }))
      );
    }
  }, [discoveryKPIs]);

  useEffect(() => {
    oldItems.current = items;
  }, [items]);

  const searchKpiLibrary = (searchTerm) => {
    const query = {
      discoveryId,
      start: 0,
      count: 100,
      order: "asc",
      searchCategories: "1"
    };

    if (searchTerm) {
      query.search = searchTerm;
      getAutocompleteDiscoveryKPILibrary(query);
    }
  };

  return (
    <ValueMapList
      type={ItemTypes.VALUE_MAP_KPI}
      title={outcomesTitleText}
      callToAction={outcomesCTA}
      emptyText={emptyText}
      unselectedHiddenText={unselectedHiddenText}
      items={filterSelected({ items })}
      totalItemsCount={items.length}
      data-cy={"outcomes-panel"}
      editMode={kpisEditMode}
      showSelectedOnly={showSelectedOnly.current}
      onToggleEdit={onToggleEdit}
      onToggleShowSelected={onToggleShowSelected}
      onSaveItem={onSaveOutcome}
      onCreateItem={onCreateKPI}
      onDeleteItem={onDeleteKPI}
      onReorder={onReorder}
      createLoadingState={createLoadingState}
      searchLibrary={searchKpiLibrary}
      searchLibraryData={kpiLibrary}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    discoveryKPIs: selectDiscoveryAllKPIs(state),
    discoveryId: selectDiscoveryId(state),
    kpisEditMode: selectDiscoverySetting(state, "canvasKPIsEditMode", false),
    kpisShowSelectedOnly: selectDiscoverySetting(
      state,
      "canvasKPIsShowSelectedOnly",
      false
    ),
    saveLoadingState: selectRequestState(
      state,
      actionTypes.DISCOVERY_SAVE_KPI_SUCCESS
    ),
    createLoadingState: selectRequestState(
      state,
      actionTypes.DISCOVERY_CREATE_KPI_REQUEST
    ),
    deleteLoadingState: selectRequestState(
      state,
      actionTypes.DISCOVERY_DELETE_KPI_REQUEST
    ),
    removeLoadingState: selectRequestState(
      state,
      actionTypes.DISCOVERY_REMOVE_KPI_FROM_DISCOVERY_REQUEST
    ),
    reorderLoadingState: selectRequestState(
      state,
      actionTypes.REORDER_DISCOVERY_KPIS_REQUEST
    ),
    restoreLoadingState: selectRequestState(
      state,
      actionTypes.DISCOVERY_RESTORE_KPI_REQUEST
    ),
    kpiLibrary: selectRequestData(
      state,
      actionTypes.GET_AUTOCOMPLETE_KPI_LIBRARY_REQUEST
    )
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveDiscoveryKPI,
      reorderDiscoveryKPIs,
      createDiscoveryKPI,
      deleteDiscoveryKPI,
      removeDiscoveryKPIFromLibrary,
      updateDiscoverySettings,
      getAutocompleteDiscoveryKPILibrary,
      addDiscoveryKPIFromLibrary
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ValueMapOutcomeList
);
