import React, { useEffect, useState } from "react";
import ReactTimeAgo from "react-time-ago";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import {
  useString as s,
  useStringTemplate
} from "../../../../components/StringProvider";
import {
  hasPermission,
  selectAllUsers,
  selectRequestState,
  selectRoles,
  selectRolesPagination
} from "../../../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import Table from "../../../../components/Table";
import { useSetting } from "../../../../components/SettingsProvider";
import RoleActionsDropdown from "./RoleActionsDropdown";
import { getRoles } from "../../../../store/actions/roles";
import RolesSearchBar from "./RolesSearchBar";
import Highlighter from "../../../../components/Highlighter";
import styled, { css } from "styled-components";
import { IntlMessageFormat } from "intl-messageformat";
import actionTypes from "../../../../store/actionTypes";
import useLoadingState from "../../../../utils/use-loading-state";
import Permissions from "../../../../utils/permissions";
import AccessManagementActions from "../AccessManagementActions";
import { useHistory } from "react-router-dom";
import NewButton from "../../../../components/NewButton";
import Icon from "../../../../components/Icon";
import { Text } from "../../../../components";
import Notification from "../../../../components/Notification";

TimeAgo.addDefaultLocale(en);

const Roles = ({
  pagination,
  roles,
  getRoles,
  loadingState,
  canEditAccessManagement
}) => {
  let history = useHistory();
  const [isRolesLoaded, setIsRolesLoaded] = useState(false);
  const [start, setStart] = useState(0);
  const [showRecordsText, setShowRecordsText] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [sort, setSort] = useState(undefined);
  const [order, setOrder] = useState(undefined);
  const [searchTerm, setSearchTerm] = useState("");
  const showRecordsTemplate = useStringTemplate(
    "accessManagement.page.roles.showRecordTemplate",
    "Showing records {start} - {end} of {total}"
  );
  const errorText = s(
    "accessManagement.page.roles.messages.error",
    "Error loading roles"
  );
  const editText = s(
    "accessManagement.page.roles.editButton.tooltip",
    "Edit Role"
  );
  const createdByDefault = s(
    "accessManagement.page.roles.createdBy.default",
    "Cuvama"
  );
  const action = s("accessManagement.page.roles.action", "Action");

  const currentPagination = pagination
    ? {
        current: Math.floor(pagination.start / pageSize) + 1,
        total: pagination.total,
        pageSize
      }
    : { current: 0, total: 0, pageSize };

  const end = () => {
    if (currentPagination.total === 0) {
      return 0;
    }

    return currentPagination.total - start > pageSize
      ? start + pageSize
      : pagination.total;
  };

  //TODO: work out how to import a file dynamically
  const locale = useSetting("locale", "en");
  // const localeFile = require(`javascript-time-ago/locale/${locale}.json`);
  // TimeAgo.addLocale(en);
  // console.log('----> locale', locale);
  // TimeAgo.addLocale(locale);

  const doQuery = () => {
    const query = {
      start,
      count: pageSize
    };

    if (searchTerm) {
      query.search = searchTerm;
    }

    if (sort) {
      query.sort = sort;
    }

    if (order) {
      query.order = order;
    }

    getRoles(query);
  };

  useEffect(() => {
    doQuery();
  }, [start, searchTerm, sort, order, pageSize]);

  useEffect(() => {
    const showRecordsText = new IntlMessageFormat(
      showRecordsTemplate,
      "en-US"
    ).format({
      start: currentPagination.total === 0 ? 0 : start + 1,
      end: end(),
      total: currentPagination.total
    });
    setShowRecordsText(showRecordsText);
  }, [pagination]);

  useLoadingState(
    loadingState,
    () => {
      if (roles?.length) setIsRolesLoaded(true);
    },
    () => {
      Notification.error(errorText);
    }
  );

  const data =
    (roles &&
      roles.map((role) => ({
        ...role,
        key: role._id,
        action: "action"
      }))) ||
    [];

  let columns = [
    {
      title: s("accessManagement.page.roles.name.header", "Role Name"),
      dataIndex: "name",
      key: "name",
      sorter: true,
      render: (name) => {
        if (searchTerm) {
          return (
            <Highlighter textToHighlight={name} searchWords={[searchTerm]} />
          );
        }
        return name;
      }
    },
    {
      title: s("accessManagement.page.roles.description.header", "Description"),
      dataIndex: "description",
      key: "description",
      sorter: true,
      render: (description) => {
        if (searchTerm) {
          return (
            <Highlighter
              textToHighlight={description}
              searchWords={[searchTerm]}
            />
          );
        }
        return !description ? "-" : description;
      }
    },
    {
      title: s("accessManagement.page.roles.createdBy.header", "Created By"),
      dataIndex: "createdBy",
      key: "createdBy",
      sorter: true,
      render: (createdBy) => {
        if (!createdBy) {
          return createdByDefault;
        }
        return createdBy;
      }
    },
    {
      title: s(
        "accessManagement.page.roles.lastActivity.header",
        "Last modified"
      ),
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: false,
      render: (dateStr) => {
        return !dateStr ? (
          "-"
        ) : (
          <ReactTimeAgo date={new Date(dateStr)} locale={locale} />
        );
      }
    }
  ];

  if (canEditAccessManagement) {
    columns.push({
      title: action,
      dataIndex: "action",
      key: "action",
      width: 95,
      fixed: true,
      render: (text, record) => {
        return (
          <>
            <NewButton
              type={"iconSecondary"}
              className="ant-dropdown-link"
              tooltip={editText}
              onClick={() => {
                history.push(
                  `/organizations/access-management/roles/edit/${record._id}`
                );
              }}
            >
              <Icon name={"pencil"} size={"medium"} colour={"gray4"} />
            </NewButton>
            <RoleActionsDropdown
              roleId={record._id}
              isSystemRole={record.isSystemRole}
            />
          </>
        );
      }
    });
  }

  const onTableChange = (pagination, filters, sorter) => {
    const mapOrder = (order) => {
      switch (order) {
        case "ascend":
          return "asc";
        case "descend":
          return "desc";
        default:
          return;
      }
    };

    if (pagination.current) {
      setStart((pagination.current - 1) * pagination.pageSize);
    }

    setPageSize(pagination.pageSize);

    if (sorter.order) {
      setSort(sorter.columnKey);
      setOrder(mapOrder(sorter.order));
    } else {
      setSort(undefined);
      setOrder(undefined);
    }
  };

  const onSearch = (e) => {
    setSearchTerm(e);
    setStart(0);
  };

  return (
    <>
      <RolesSearchBar onSearch={onSearch} />
      <Container data-cy={"users-table"}>
        <Table
          columns={columns}
          dataSource={data}
          onChange={onTableChange}
          pagination={currentPagination}
          variant={"smallBody"}
        />
      </Container>
      {isRolesLoaded && (
        <RecordsText data-cy={"records-text"} isDataEmpty={!!data?.length}>
          <Text color={"gray4"} variant={"bodyMobile"}>
            {showRecordsText}
          </Text>
        </RecordsText>
      )}
      <AccessManagementActions />
    </>
  );
};

const RecordsText = styled.div`
  font-size: 12px;
  transform: translateY(-52px);
  width: fit-content;

  ${({ isDataEmpty }) =>
    !isDataEmpty &&
    css`
      transform: translateY(0px);
      margin-top: 16px;
    `}
`;

const mapStateToProps = (state) => {
  return {
    allUsers: selectAllUsers(state),
    roles: selectRoles(state),
    pagination: selectRolesPagination(state),
    loadingState: selectRequestState(state, actionTypes.GET_ROLES_REQUEST),
    canEditAccessManagement: hasPermission(
      state,
      Permissions.EDIT_ACCESS_MANAGEMENT
    )
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getRoles
    },
    dispatch
  );

const Container = styled.div``;

export default compose(connect(mapStateToProps, mapDispatchToProps))(Roles);
