import React from "react";
import { createGlobalStyle } from "styled-components";
import { BrowserRouter, Route } from "react-router-dom";
import Helmet from "react-helmet";
import Routes from "../routes";
import { themeProp } from "../theme";
import SettingProvider from "../components/SettingsProvider";
import { useString as s } from "../components/StringProvider";
import FeaturesProvider from "../components/FeaturesProvider";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import ErrorBoundary from "../components/ErrorBoundary";
import {
  UserPilotPageTracker,
  GuestUserTracker
} from "../components/UserTracking";
import { FullscreenProvider, NotificationMessage } from "../components";
import AuthenticationHandler from "./AuthenticationHandler";

const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

const App = () => {
  if (inIframe()) {
    console.log("Running in iframe");
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <FullscreenProvider>
        <SettingProvider>
          <FeaturesProvider>
            <BrowserRouter>
              <UserPilotPageTracker />
              <GuestUserTracker />
              <GlobalStyle />
              <Helmet>
                <title>
                  {s("page.header.title", "{companyName} Outcomes Discovery")}
                </title>
              </Helmet>
              <ErrorBoundary>
                <AuthenticationHandler>
                  <Route component={Routes} />
                  <NotificationMessage />
                </AuthenticationHandler>
              </ErrorBoundary>
            </BrowserRouter>
          </FeaturesProvider>
        </SettingProvider>
      </FullscreenProvider>
    </DndProvider>
  );
};

const GlobalStyle = createGlobalStyle`
  body {
    background: ${themeProp("palette.background")};    
    font-family: ${themeProp("typography.body.fontFamily")};
    font-size: ${themeProp("typography.body.fontSize")};
    font-weight: ${themeProp("typography.body.fontWeight")};;
    line-height: ${themeProp("typography.body.lineHeight")};
    color: ${themeProp("palette.text")};
    
    @media print {
      background: ${themeProp("palette.surface")} !important; 
    }
    
    &.scrollbar-gap {
      overflow-y: hidden;
      padding-right: ${themeProp("constants.scrollBarWidth")}px;
    }
  }
  
  .ant-popover-inner-content {
    padding: 0;
  }
  
  .ant-notification-notice.ant-notification-notice-closable {
    display: flex;
    
    & .ant-notification-notice-content {
      flex-grow: 1;
    }
  }

  .ant-notification-notice-close {
    position: static;
  }

  .ant-notification-notice {
    font-family: ${themeProp("typography.body.fontFamily")};
  }

  .ant-notification-notice-message {
    margin-bottom: 0px !important;
  } 

  .anticon-info-circle {
    color: ${themeProp("palette.info")};
  }
  
  .ant-dropdown-menu-title-content {
    white-space: nowrap;
  }

  .ant-message-success, .ant-message-info {
    display: flex;
    gap: 8px
  }

  .ant-message-success svg, .ant-message-info svg {
    height: 24px;
    width: 24px;
  }

  .ant-message-success span, .ant-message-info span {
    font-size: ${themeProp("typography.body.fontSize")};
    font-weight: ${themeProp("typography.body.fontWeight")};
    color: ${themeProp("palette.gray4")};
  }
`;

export default App;
