import PropType from "prop-types";
import JobTypes from "../../../utils/job-types";
import { Redirect } from "react-router-dom";
import ErrorPage from "../../ErrorPage";
import { useString as s } from "../../../components/StringProvider";
import { useEffect } from "react";
import Notification from "../../../components/Notification";

const DeploymentSuccess = ({ type }) => {
  const messages = {};

  messages[JobTypes.MD_CONFIGURATION_DEPLOYMENT] = s(
    `deploymentJob.page.deploymentSuccess.mdConfigurationDeployment`,
    "Master Data was uploaded. You can now use the new configuration."
  );

  messages[JobTypes.CRM_CONFIGURATION_DEPLOYMENT] = s(
    `deploymentJob.page.deploymentSuccess.crmConfigurationDeployment`,
    "CRM Data was updated. You can now sync the discoveries."
  );

  messages[JobTypes.AI_EMAIL_TEMPLATES_DEPLOYMENT] = s(
    `deploymentJob.page.deploymentSuccess.aiEmailTemplatesDeployment`,
    "AI email templates updated"
  );

  useEffect(() => {
    Notification.success(messages[type]);
  }, []);

  switch (type) {
    case JobTypes.MD_CONFIGURATION_DEPLOYMENT:
      return <Redirect to={"/organizations/manage-configuration"} />;
    case JobTypes.CRM_CONFIGURATION_DEPLOYMENT:
      return <Redirect to={"/organizations/upload-crm-data"} />;
  }

  return <ErrorPage />;
};

DeploymentSuccess.propTypes = {
  type: PropType.oneOf(JobTypes.ALL)
};

export default DeploymentSuccess;
