import OrganizationHeader from "../OrganizationHeader";
import { OrganizationPageLayout } from "../../../components/Layout";
import Heading from "../../../components/Heading";
import { withRouter } from "react-router-dom";
import Page from "../../../components/Page";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { selectRequestData, selectRequestState } from "../../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { useString as s } from "../../../components/StringProvider";
import { Space } from "antd";
import TextBody from "../../../components/TextBody";
import { Text } from "../../../components";
import AboutThisCard from "../../../components/Discovery/AboutThisCard";
import {
  FileDragger,
  FileUpload,
  ValidationErrors
} from "../ManageConfiguration/components";
import ValidationWorkflowTypes from "../../../utils/validation-workflow-types";
import {
  createValidation,
  deployValidation
} from "../../../store/actions/validations";
import actionTypes from "../../../store/actionTypes";
import ValidationStatuses from "../../../utils/validation-statuses";
import useLoadingState from "../../../utils/use-loading-state";
import NewButton from "../../../components/NewButton";
import { getLatestCRMAccountsLog } from "../../../store/actions/config";
import * as formatting from "../../../utils/formatting";
import Notification from "../../../components/Notification";

const States = {
  READY: "ready",
  UPLOAD_COMPLETE: "upload_complete",
  VALIDATING: "validating",
  VALIDATION_FAILED: "validation_failed",
  VALIDATION_COMPLETE: "validation_complete",
  VALIDATION_COMPLETE_WITH_ERRORS: "validation_complete_with_errors"
};

const UploadCRMData = ({
  createFileData,
  createValidationLoadingState,
  createValidation,
  validation,
  deployValidation,
  deployValidationLoadingState,
  deploymentJob,
  getLatestCRMAccountsLog,
  log,
  history
}) => {
  const [fileList, setFileList] = useState([]);
  const [state, setState] = useState(States.READY);
  const header = s("organization.page.uploadCRMData.header", "Upload CRM Data");
  const subheader = s(
    "organization.page.uploadCRMData.subheader",
    "To enable matching discoveries to CRM opportunities, please upload your CRM data file."
  );
  const lastUpdated = s(
    "organization.page.uploadCRMData.lastModified",
    "Last updated: {lastUpdatedTime}",
    {
      lastUpdatedTime:
        log && log.updatedAt ? formatting.timeSince(log.updatedAt) : log
    }
  );
  const aboutThis = s(
    "organization.page.uploadCRMData.aboutThis",
    "What file should I upload"
  );
  const pleasePrepare = s(
    "organization.page.uploadCRMData.pleasePrepare",
    "Please prepare a csv file with 4 columns (required):"
  );
  const optionallyPrepare = s(
    "organization.page.uploadCRMData.optionallyPrepare",
    "Optionally, you can also add the following columns to improve reporting:"
  );
  const deploy = s("organization.page.uploadCRMData.deploy", "Update CRM Data");
  const deploymentError = s(
    "organization.page.uploadCRMData.messages.deploymentError",
    "Error while deploying CRM data"
  );
  const columns = [];

  columns.push(
    s("organization.page.uploadCRMData.columns.accountId", "accountId")
  );

  columns.push(
    s("organization.page.uploadCRMData.columns.accountName", "accountName")
  );

  columns.push(
    s("organization.page.uploadCRMData.columns.opportunityId", "opportunityId")
  );

  columns.push(
    s(
      "organization.page.uploadCRMData.columns.opportunityName",
      "opportunityName"
    )
  );

  const optionalColumns = [];
  optionalColumns.push(
    s(
      "organization.page.uploadCRMData.columns.opportunityOwner",
      "opportunityOwner"
    )
  );
  optionalColumns.push(
    s(
      "organization.page.uploadCRMData.columns.opportunityOwnerTeam",
      "opportunityOwnerTeam"
    )
  );
  optionalColumns.push(
    s(
      "organization.page.uploadCRMData.columns.opportunityCreatedDate",
      "opportunityCreatedDate"
    )
  );
  optionalColumns.push(
    s(
      "organization.page.uploadCRMData.columns.opportunityStage",
      "opportunityStage"
    )
  );
  optionalColumns.push(
    s(
      "organization.page.uploadCRMData.columns.opportunityCloseDate",
      "opportunityCloseDate"
    )
  );
  optionalColumns.push(
    s(
      "organization.page.uploadCRMData.columns.opportunityShouldHaveDiscovery",
      "opportunityShouldHaveDiscovery"
    )
  );
  optionalColumns.push(
    s(
      "organization.page.uploadCRMData.columns.opportunityType",
      "opportunityType"
    )
  );
  optionalColumns.push(
    s(
      "organization.page.uploadCRMData.columns.opportunityValue",
      "opportunityValue"
    )
  );

  const onStartUpload = (file) => {
    setFileList((list) => [...list, file]);
    return false;
  };

  const onDelete = (file) => {
    setFileList((list) => list.filter((f) => f !== file));
    setState(States.READY);
  };

  const onUploadComplete = () => {
    createValidation({
      fileId: createFileData.file._id,
      workflowType: ValidationWorkflowTypes.CRM_ACCOUNTS
    });
    setState(States.UPLOAD_COMPLETE);
  };

  const onDeploy = () => {
    deployValidation({ validationId: validation._id });
  };

  useEffect(() => {
    if (state === States.READY) {
      getLatestCRMAccountsLog();
    }
  }, [state]);

  useLoadingState(
    createValidationLoadingState,
    () => {
      setState(
        validation.status === ValidationStatuses.VALIDATED
          ? States.VALIDATION_COMPLETE
          : States.VALIDATION_COMPLETE_WITH_ERRORS
      );
    },
    () => {
      setState(States.VALIDATION_FAILED);
    }
  );

  useLoadingState(
    deployValidationLoadingState,
    () => {
      history.push(`/organizations/deployments/${deploymentJob._id}`);
    },
    () => Notification.error(deploymentError)
  );

  return (
    <Page header={<OrganizationHeader />}>
      <OrganizationPageLayout>
        <Space direction={"vertical"} size={24}>
          <Space direction={"vertical"} size={6}>
            <Heading level={"h4"}>{header}</Heading>
            <TextBody>{subheader}</TextBody>
            <Text color={"gray4"} variant={"small"}>
              {lastUpdated}
            </Text>
          </Space>
          <AboutThisCard text={aboutThis}>
            <TextBody>{pleasePrepare}</TextBody>
            <ListContainer>
              {columns.map((column, index) => (
                <li key={index}>{column}</li>
              ))}
            </ListContainer>
            <TextBody>{optionallyPrepare}</TextBody>
            <ListContainer>
              {optionalColumns.map((column, index) => (
                <li key={index}>{column}</li>
              ))}
            </ListContainer>
          </AboutThisCard>
          <Container>
            {fileList.length === 0 ? (
              <FileDragger acceptTypes={".csv"} onStartUpload={onStartUpload} />
            ) : (
              fileList.map((file, index) => (
                <FileUpload
                  key={index}
                  file={file}
                  onDelete={onDelete}
                  onUploadComplete={onUploadComplete}
                />
              ))
            )}
            {state === States.VALIDATION_COMPLETE_WITH_ERRORS ? (
              <ValidationErrors validation={validation} />
            ) : null}
            {state === States.VALIDATION_COMPLETE ? (
              <DeploymentContainer>
                <NewButton type={"primary"} onClick={onDeploy}>
                  {deploy}
                </NewButton>
              </DeploymentContainer>
            ) : null}
          </Container>
        </Space>
      </OrganizationPageLayout>
    </Page>
  );
};

const ListContainer = styled.ul`
  list-style-type: none;
  margin-left: 30px;

  & li {
    position: relative;
  }

  & li:before {
    content: "•";
    padding: 0 30px 0 0;
    position: absolute;
    top: 0;
    left: -15px;
  }
`;

const DeploymentContainer = styled.div`
  margin-top: 40px;
`;
const Container = styled.div`
  width: 100%;
`;

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createValidation,
      deployValidation,
      getLatestCRMAccountsLog
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  createFileData: selectRequestData(state, actionTypes.CREATE_FILE_REQUEST),
  createValidationLoadingState: selectRequestState(
    state,
    actionTypes.CREATE_VALIDATION_REQUEST
  ),
  validation: selectRequestData(state, actionTypes.CREATE_VALIDATION_REQUEST),
  deployValidationLoadingState: selectRequestState(
    state,
    actionTypes.DEPLOY_VALIDATION_REQUEST
  ),
  deploymentJob: selectRequestData(
    state,
    actionTypes.DEPLOY_VALIDATION_REQUEST
  ),
  log: selectRequestData(state, actionTypes.GET_LATEST_CRM_ACCOUNTS_LOG_REQUEST)
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(UploadCRMData);
