import PropTypes from "prop-types";
import { useEffect } from "react";
import {
  selectFileFileId,
  selectFileState,
  selectFileUploadPercentage,
  selectFileValidationErrors,
  selectRequestData
} from "../../../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { createFile, uploadFile } from "../../../../store/actions/files";
import actionTypes from "../../../../store/actionTypes";
import { createValidation } from "../../../../store/actions/validations";
import ValidationWorkflowTypes from "../../../../utils/validation-workflow-types";
import TextBody from "../../../../components/TextBody";
import { Text } from "../../../../components";
import Icon from "../../../../components/Icon";
import NewButton from "../../../../components/NewButton";
import * as formatting from "../../../../utils/formatting";
import Progress from "../../../../components/Progress";
import styled from "styled-components";
import { themeProp } from "../../../../theme";
import { useString as s } from "../../../../components/StringProvider";
import { FileStates } from "../../../../store/reducers/file-list";
import { removeFileFromList } from "../../../../store/actions/file-list";
import ValidationErrorCodes from "../../../../utils/validation-error-codes";

const addPeriod = (s) => {
  if (!s.match(/.*\.$/)) {
    return s + ".";
  }

  return s;
};
const PowerpointFileStatus = ({
  file,
  fileId,
  createFile,
  createFileData,
  uploadFile,
  createValidation,
  removeFileFromList,
  state,
  validationErrors,
  uploadPercentage
}) => {
  const controller = new AbortController();
  const cancelTooltip = s(
    "manageConfiguration.page.fileUpload.remove",
    "Remove"
  );
  const removeOrUpload = s(
    "manageConfiguration.page.fileUpload.removeOrUpload",
    "Remove this file or drag & drop new file"
  );
  const messages = {};
  const warnings = {};

  messages[FileStates.READY] = { text: "" };
  messages[FileStates.UPLOADING] = {
    text: s("manageConfiguration.page.fileUpload.uploading", "Uploading...")
  };
  messages[FileStates.UPLOAD_ERROR] = {
    color: "error",
    text: s("manageConfiguration.page.fileUpload.uploadError", "Upload error")
  };
  messages[FileStates.UPLOAD_COMPLETE] = {
    text: s(
      "manageConfiguration.page.fileUpload.uploadComplete",
      "Upload complete"
    )
  };
  messages[FileStates.VALIDATING] = {
    text: s(
      "manageConfiguration.page.fileUpload.validating",
      "Validating your template..."
    )
  };
  messages[FileStates.VALIDATION_ERROR] = {
    color: "error",
    text: s(
      "manageConfiguration.page.fileUpload.validationError",
      "Validation error"
    )
  };
  messages[FileStates.VALIDATION_COMPLETE] = {
    text: s(
      "manageConfiguration.page.fileUpload.readyForUpload",
      "Ready for Upload"
    )
  };
  messages[FileStates.VALIDATION_COMPLETE_WITH_ERRORS] = {
    color: "error",
    text: s(
      "manageConfiguration.page.fileUpload.validationCompleteWithErrors",
      "Error detected"
    )
  };
  messages[FileStates.CANCELLING] = {
    color: "error",
    text: s("manageConfiguration.page.fileUpload.cancelling", "Cancelling...")
  };
  warnings[ValidationErrorCodes.POWERPOINT_TEMPLATE_FILE_ALREADY_EXISTS] = {
    text: s(
      "update_powerpoint_templates.popup.messages.POWERPOINT_TEMPLATE_FILE_ALREADY_EXISTS",
      "If you upload this file, it will replace an existing template with the same name."
    )
  };
  warnings[ValidationErrorCodes.POWERPOINT_TEMPLATE_FILE_NOT_FOUND_IN_MD] = {
    text: s(
      "update_powerpoint_templates.popup.messages.POWERPOINT_TEMPLATE_FILE_NOT_FOUND_IN_MD",
      "If this template name is correct, upload files and go to Master Data to add the file name."
    )
  };

  useEffect(() => {
    createFile({
      name: file.name,
      size: file.size,
      type: file.type,
      temporary: true,
      description: file.uid
    });
  }, [file]);

  useEffect(() => {
    if (fileId) {
      uploadFile({
        url: createFileData.url,
        file,
        controller
      });
    }
  }, [fileId]);

  useEffect(() => {
    switch (state) {
      case FileStates.UPLOAD_COMPLETE:
        createValidation({
          fileId: fileId,
          workflowType: ValidationWorkflowTypes.POWERPOINT_TEMPLATE
        });
        break;
    }
  }, [state]);

  const onCancel = () => {
    controller.abort();

    if (state === FileStates.UPLOADING) {
      controller.abort();
    }

    removeFileFromList({ file });
  };

  switch (state) {
    case FileStates.VALIDATION_COMPLETE:
      return (
        <Container>
          <Row>
            <Icon
              name={"powerpoint"}
              size={"medium"}
              colour={"gray3"}
              className={"icon"}
            />
            <TextBody className={"name"}>{file.name}</TextBody>
            <Text
              className={"percent"}
              variant={"small"}
              color={messages[state]?.color || "gray4"}
            >
              {messages[state]?.text}
            </Text>
            <Icon name={"check"} colour={"success"} />
            <NewButton
              type={"iconSecondary"}
              onClick={onCancel}
              tooltip={cancelTooltip}
              className={"cancel"}
            >
              <Icon name={"close"} />
            </NewButton>
          </Row>
        </Container>
      );

    case FileStates.VALIDATION_COMPLETE_WITH_ERRORS:
      return (
        <Container>
          <Row>
            <Icon
              name={"powerpoint"}
              size={"medium"}
              colour={"gray3"}
              className={"icon"}
            />
            <TextBody className={"name"}>{file.name}</TextBody>
            <Text className={"percent"} variant={"small"} color={"warning"}>
              {addPeriod(validationErrors[0].description)} {removeOrUpload}
            </Text>
            <NewButton
              type={"iconSecondary"}
              onClick={onCancel}
              tooltip={cancelTooltip}
              className={"cancel"}
            >
              <Icon name={"close"} />
            </NewButton>
          </Row>
          <Row>
            <WarningContainer>
              <Text data-cy={"upload-validation-message"}>
                {warnings[validationErrors[0].code]?.text}
              </Text>
            </WarningContainer>
          </Row>
        </Container>
      );

    default:
      return (
        <Container>
          <Row>
            <Icon
              name={"powerpoint"}
              size={"medium"}
              colour={"gray3"}
              className={"icon"}
            />
            <TextBody className={"name"}>{file.name}</TextBody>
            <Text className={"percent"} variant={"small"} color={"gray4"}>
              {formatting.formatPercentage({ value: uploadPercentage })}
            </Text>
            <NewButton
              type={"iconSecondary"}
              onClick={onCancel}
              tooltip={cancelTooltip}
            >
              <Icon name={"close"} />
            </NewButton>
          </Row>

          <Row>
            <Progress percent={uploadPercentage} color={"primary"} />
          </Row>
          <Row>
            <Text color={messages[state]?.color || "gray4"} variant={"small"}>
              {messages[state]?.text}
            </Text>
          </Row>
        </Container>
      );
  }
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  line-height: ${themeProp(`typography.body.lineHeight`)};

  & .icon {
    height: 20px;
    width: 20px;
    margin-left: 4px;
  }

  & .name {
    flex: 1;
    margin-left: 12px;
  }

  & .percent {
    margin-right: 11px;
    text-align: right;
  }

  & .ant-progress-text {
    display: none;
  }

  & .cancel {
    margin-left: 8px;
  }
`;

const WarningContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 16px;

  width: 100%;

  background: rgba(245, 117, 24, 0.05);
  border-radius: 6px;

  & > *:first-child {
    flex-grow: 1;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

PowerpointFileStatus.propTyps = {
  file: PropTypes.object.isRequired
};

const mapStateToProps = (state, props) => ({
  createFileData: selectRequestData(state, actionTypes.CREATE_FILE_REQUEST),
  state: selectFileState(state, props.file.uid),
  fileId: selectFileFileId(state, props.file.uid),
  validationErrors: selectFileValidationErrors(state, props.file.uid),
  uploadPercentage: selectFileUploadPercentage(state, props.file.uid)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createFile,
      uploadFile,
      createValidation,
      removeFileFromList
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  PowerpointFileStatus
);
