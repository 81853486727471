import React, { useState } from "react";
import { useString as s } from "../../../../components/StringProvider";
import NewButton from "../../../../components/NewButton";
import { Space } from "antd";
import Icon from "../../../../components/Icon";
import { hasPermission } from "../../../../store/reducers";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Permissions from "../../../../utils/permissions";
import styled from "styled-components";
import SearchBar from "../../../../components/SearchBar";
import httpService from "../../../../services/http.service";
import openDownload from "../../../../utils/open-download";
import CreateUserModal from "./CreateUserModal";
import * as userTypes from "../../../../utils/user-types";
import AddUsersInBulkModal from "./AddUsersInBulkModal";
import Notification from "../../../../components/Notification";

const UsersSearchBar = ({
  canEditAccessManagement,
  onSearch,
  userType,
  doUsersRefresh
}) => {
  const [showNewUserModal, setShowNewUserModal] = useState(false);
  const [showAddUsersInBulkModal, setShowAddUsersInBulkModal] = useState(false);

  const onNewUser = () => {
    setShowNewUserModal(true);
  };

  const onCloseNewUser = () => {
    setShowNewUserModal(false);
  };

  const onAddUsersInBulk = () => {
    setShowAddUsersInBulkModal(true);
  };

  const onCloseAddUsersInBulk = () => {
    setShowAddUsersInBulkModal(false);
  };

  const internalUserSearchPlaceholderText = s(
    "accessManagement.page.users.search.placeholder.internal",
    "Search by email, name, job title, or role"
  );
  const externalUserSearchPlaceholderText = s(
    "accessManagement.page.users.search.placeholder.external",
    "Search by email or role"
  );
  const exportText = s("accessManagement.page.users.export.button", "Export");
  const newUser = s("accessManagement.page.users.newuser.button", "Add User");
  const addUsersInBulk = s(
    "accessManagement.page.users.addUsersInBulk.button",
    "Add Users in Bulk"
  );
  const downloadUsersError = s(
    "error.EXPORT_USERS_FAILURE",
    "Failed to download users"
  );

  const onExportClick = () => {
    httpService
      .post(`/users/download`, undefined, undefined, {
        userType,
        all: true
      })
      .then(({ data }) => {
        openDownload(data.url);
      })
      .catch(() => {
        Notification.error(downloadUsersError);
      });
  };

  return (
    <Header>
      <HeaderLeftSection>
        <SearchBar
          placeholder={
            userType === userTypes.INTERNAL
              ? internalUserSearchPlaceholderText
              : externalUserSearchPlaceholderText
          }
          onSearch={onSearch}
          data-cy={"search-user"}
        />
      </HeaderLeftSection>
      <HeaderRightSection>
        <Space size={20}>
          <NewButton
            type={"secondary"}
            onClick={onExportClick}
            data-cy={"export-users"}
          >
            <Icon name={"download"} />
            {exportText}
          </NewButton>
          {canEditAccessManagement && userType === userTypes.INTERNAL ? (
            <NewButton
              type={"secondary"}
              onClick={onAddUsersInBulk}
              data-cy={"add-users-in-bulk-button"}
            >
              <Icon name={"cloudUpload"} size={18} />
              {addUsersInBulk}
            </NewButton>
          ) : null}
          {canEditAccessManagement && userType === userTypes.INTERNAL ? (
            <NewButton
              type={"primary"}
              onClick={onNewUser}
              data-cy={"new-user-button"}
            >
              {newUser}
            </NewButton>
          ) : null}
          {showNewUserModal && (
            <CreateUserModal
              onClose={onCloseNewUser}
              doUsersRefresh={doUsersRefresh}
              userType={userType}
            />
          )}
          {showAddUsersInBulkModal && (
            <AddUsersInBulkModal
              onClose={onCloseAddUsersInBulk}
              doUsersRefresh={doUsersRefresh}
              userType={userType}
            />
          )}
        </Space>
      </HeaderRightSection>
    </Header>
  );
};

export const HeaderRightSection = styled(Space)`
  display: flex;
  justify-content: flex-end;
  height: 100%;
  padding-top: 0;
  align-self: flex-end;
`;

export const HeaderLeftSection = styled(Space)`
  display: flex;
  justify-content: flex-start;
  padding-top: 15px;
`;

const Header = styled.div`
  height: 70px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;

  & .ant-input-search {
    width: 400px;
  }
`;

const mapStateToProps = (state) => {
  return {
    canEditAccessManagement: hasPermission(
      state,
      Permissions.EDIT_ACCESS_MANAGEMENT
    )
  };
};

export default compose(withRouter, connect(mapStateToProps))(UsersSearchBar);
