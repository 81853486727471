const NotificationTypes = {
  SUCCESS: "success",
  INFO: "info",
  WARNING: "warning",
  ERROR: "error"
};

NotificationTypes.ALL = Object.values(NotificationTypes);

export default NotificationTypes;
