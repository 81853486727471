import Form, { FormItem } from "../../../../components/Form";
import React, { useEffect, useState } from "react";
import { useString as s } from "../../../../components/StringProvider";
import NewButton from "../../../../components/NewButton";
import { Space } from "antd";
import {
  selectRequestData,
  selectRequestState
} from "../../../../store/reducers";
import types from "../../../../store/actionTypes";
import { bindActionCreators, compose } from "redux";
import {
  getAdminSettings,
  updateAdminSettings
} from "../../../../store/actions/config";
import { connect } from "react-redux";
import useLoadingState from "../../../../utils/use-loading-state";
import ErrorPage from "../../../ErrorPage";
import Radio, { RadioGroup } from "../../../../components/Radio";
import { ErrorNotificationBox } from "../../../../components/NotificationBox";
import RegisterInterestPlacements from "../../../../utils/register-interest-placements";
import Notification from "../../../../components/Notification";

const ProspectLedDiscovery = ({
  adminSettings,
  getAdminSettings,
  updateAdminSettings,
  loadingState,
  updateLoadingState
}) => {
  const [form] = Form.useForm();
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [registerInterestPlacementValid, setRegisterInterestPlacementValid] =
    useState(false);
  const [ready, setReady] = useState(false);
  const [error, setError] = useState(false);
  const [updateError, setUpdateError] = useState(false);

  const submitButton = s(
    "generalAdmin.page.prospectLedDiscovery.submit.label",
    "Save"
  );

  const formPlacementLabel = s(
    "generalAdmin.page.prospectLedDiscovery.formPlacement.label",
    "Form Placement"
  );

  const formPlacementValidation = s(
    "generalAdmin.page.prospectLedDiscovery.formPlacement.validation",
    "Form Placement Validation"
  );

  const afterSelectChallengesLabel = s(
    "generalAdmin.page.prospectLedDiscovery.formPlacement.afterSelectChallenges",
    "After Select Challenges"
  );

  const afterSelectKPIsLabel = s(
    "generalAdmin.page.prospectLedDiscovery.formPlacement.afterSelectKPIs",
    "After Select Outcomes"
  );

  const afterMetricsLabel = s(
    "generalAdmin.page.prospectLedDiscovery.formPlacement.afterMetrics",
    "After Inputs"
  );

  const updateSuccess = s(
    "generalAdmin.page.prospectLedDiscovery.messages.updateSuccess",
    "Updated settings"
  );

  const updateErrorTitle = s(
    "generalAdmin.page.prospectLedDiscovery.messages.updateErrorTitle",
    "Oops!"
  );

  const updateErrorDescription = s(
    "generalAdmin.page.prospectLedDiscovery.messages.updateErrorDescription",
    "Failed to update settings"
  );

  useEffect(() => {
    getAdminSettings();
  }, []);

  useLoadingState(
    loadingState,
    () => {
      setReady(true);
      setError(false);
    },
    () => {
      setReady(true);
      setError(true);
    }
  );

  useLoadingState(
    updateLoadingState,
    () => {
      Notification.success(updateSuccess);
    },
    () => {
      setUpdateError(true);
    }
  );

  useEffect(() => {
    if (adminSettings?.prospectLedDiscovery) {
      form.setFieldsValue(adminSettings.prospectLedDiscovery);
      onValuesChange(adminSettings.prospectLedDiscovery);
    } else {
      form.resetFields();
    }
  }, [adminSettings]);

  useEffect(() => {
    setSubmitEnabled(registerInterestPlacementValid);
  }, [registerInterestPlacementValid]);

  const onValuesChange = (values) => {
    if (values.hasOwnProperty("registerInterestPlacement")) {
      setRegisterInterestPlacementValid(
        values["registerInterestPlacement"] &&
          values["registerInterestPlacement"] !==
            adminSettings?.prospectLedDiscovery?.registerInterestPlacement
      );
    }
  };

  const onFinish = (values) => {
    setUpdateError(false);
    updateAdminSettings({ prospectLedDiscovery: values });
  };

  if (!ready) {
    return null;
  }

  if (error) {
    return <ErrorPage />;
  }

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      onValuesChange={onValuesChange}
    >
      <FormItem
        label={formPlacementLabel}
        name="registerInterestPlacement"
        rules={[
          {
            required: true,
            message: formPlacementValidation,
            whitespace: true
          }
        ]}
      >
        <RadioGroup>
          <Space direction={"vertical"} size={8}>
            <Radio value={RegisterInterestPlacements.AFTER_SELECT_CHALLENGES}>
              {afterSelectChallengesLabel}
            </Radio>
            <Radio value={RegisterInterestPlacements.AFTER_SELECT_KPIS}>
              {afterSelectKPIsLabel}
            </Radio>
            <Radio value={RegisterInterestPlacements.AFTER_METRICS}>
              {afterMetricsLabel}
            </Radio>
          </Space>
        </RadioGroup>
      </FormItem>
      <FormItem>
        <Space size={16}>
          <NewButton type={"submit"} disabled={!submitEnabled}>
            {submitButton}
          </NewButton>
        </Space>
      </FormItem>
      {updateError && (
        <ErrorNotificationBox
          title={updateErrorTitle}
          description={updateErrorDescription}
        />
      )}
    </Form>
  );
};

const mapStateToProps = (state) => ({
  loadingState: selectRequestState(state, types.GET_ADMIN_SETTINGS_REQUEST),
  adminSettings: selectRequestData(state, types.GET_ADMIN_SETTINGS_REQUEST),
  updateLoadingState: selectRequestState(
    state,
    types.UPDATE_ADMIN_SETTINGS_REQUEST
  )
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAdminSettings,
      updateAdminSettings
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ProspectLedDiscovery
);
