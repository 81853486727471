import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useEffect } from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { isFullscreen } from "../store/reducers";
import { setFullscreen } from "../store/actions/fullscreen";
import styled from "styled-components";
import { notification } from "antd";

export const POPUP_CONTAINER = "fullscreenContainer";

const FullscreenProvider = ({ children, isFullscreen, setFullscreen }) => {
  const handle = useFullScreenHandle();

  notification.config({
    getContainer: () => document.getElementById(POPUP_CONTAINER)
  });

  useEffect(() => {
    if (isFullscreen && !handle.active) {
      handle.enter();
    } else if (handle.active) {
      handle.exit();
    }
  }, [isFullscreen]);

  useEffect(() => {
    if (!isFullscreen && handle.active) {
      setFullscreen({ fullscreen: true });
    } else if (isFullscreen && !handle.active) {
      setFullscreen({ fullscreen: false });
    }
  }, [handle.active]);

  return (
    <FullScreen handle={handle}>
      <FullscreenContainer id={POPUP_CONTAINER}>{children}</FullscreenContainer>
    </FullScreen>
  );
};

const FullscreenContainer = styled.div`
  background: inherit;
`;

const mapStateToProps = (state) => ({
  isFullscreen: isFullscreen(state)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setFullscreen
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  FullscreenProvider
);
