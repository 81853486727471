import { notification } from "antd";
import PropTypes from "prop-types";
import NotificationTypes from "../utils/constants/notification-types";
import { InfoCircleOutlined } from "@ant-design/icons";

const DEFAULT_PLACEMENT = "top";
const DEFAULT_DURATION = 3.5;

const Notification = ({
  type,
  message,
  description,
  placement = DEFAULT_PLACEMENT
}) => {
  return (
    <>
      {notification[type]({
        message,
        description,
        placement
      })}
    </>
  );
};

const createNotificationMethod = (type, icon) => (options) => {
  let config = {
    placement: options.placement || DEFAULT_PLACEMENT
  };

  if (icon) {
    config.icon = icon;
  }

  if (typeof options === "string") {
    config.message = options;
  } else {
    config = {
      ...config,
      message: options.message || "",
      description: options.description || ""
    };
  }

  notification[type]({ ...config, duration: DEFAULT_DURATION });
};

Notification.error = createNotificationMethod("error");
Notification.success = createNotificationMethod("success");
Notification.info = createNotificationMethod("info", <InfoCircleOutlined />);
Notification.warning = createNotificationMethod("warning");
Notification.open = createNotificationMethod("open");

Notification.propTypes = {
  type: PropTypes.oneOf(NotificationTypes.ALL).isRequired,
  message: PropTypes.string,
  description: PropTypes.string,
  placement: PropTypes.oneOf([
    "top",
    "bottom",
    "topLeft",
    "topRight",
    "bottomLeft",
    "bottomRight"
  ])
};

export default Notification;
